import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataSlider from "@/data/Slider/DataSlider";
import ApiSlider from "@/api/ApiSlider";
import ApiOffer from "@/api/ApiOffer";
let SliderList = class SliderList extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataSlider.SliderListBreadcrumbs;
        this.TableHeaders = DataSlider.SliderDisplayTableHeaders;
        this.TableItems = [];
        this.isOpenSliderDialog = false;
        this.isOpenOfferDialog = false;
        this.editSlider = {
            uuid: "",
            name: "",
            priority: "",
        };
        this.editOffer = {
            uuid: "",
            name: "",
            priority: "",
        };
    }
    CloseSliderDialog() {
        this.editSlider.uuid = "";
        this.editSlider.name = "";
        this.editSlider.priority = "";
        this.isOpenSliderDialog = false;
    }
    CloseOfferDialog() {
        this.editOffer.uuid = "";
        this.editOffer.name = "";
        this.editOffer.priority = "";
        this.isOpenOfferDialog = false;
    }
    async OpenSliderDialog(item) {
        if (item) {
            this.editSlider.uuid = item.uuid;
            this.editSlider.name = "";
            this.editSlider.priority = "";
            this.isOpenSliderDialog = !this.isOpenSliderDialog;
            const sliderInfo = await ApiSlider.GetSliderByUUID(ApiEnter.CurrentSessionUUID, this.editSlider.uuid);
            if (sliderInfo == undefined) {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                }).then(() => {
                    this.CloseSliderDialog();
                });
                return;
            }
            this.editSlider.name = sliderInfo.name;
            this.editSlider.priority = sliderInfo.slider_sort;
        }
        else {
            this.isOpenSliderDialog = !this.isOpenSliderDialog;
        }
    }
    async OpenOfferDialog(item) {
        if (item) {
            this.editOffer.uuid = item.uuid;
            this.editOffer.name = "";
            this.editOffer.priority = "";
            this.isOpenOfferDialog = !this.isOpenOfferDialog;
            const offerInfo = await ApiOffer.GetOfferByUUID(ApiEnter.CurrentSessionUUID, this.editOffer.uuid);
            if (offerInfo == undefined) {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                }).then(() => {
                    this.CloseOfferDialog();
                });
                return;
            }
            this.editOffer.name = offerInfo.name;
            this.editOffer.priority = offerInfo.slider_sort;
        }
        else {
            this.isOpenOfferDialog = !this.isOpenOfferDialog;
        }
    }
    async UpdateSliderSort() {
        try {
            const response = await ApiSlider.UpdateSliderSort(this.editSlider.priority, ApiEnter.CurrentSessionUUID, this.editSlider.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.CloseSliderDialog();
                    this.GetSlider();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async UpdateOfferSort() {
        try {
            const response = await ApiSlider.UpdateOfferSort(this.editOffer.priority, ApiEnter.CurrentSessionUUID, this.editOffer.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.CloseOfferDialog();
                    this.GetSlider();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async GetSlider() {
        try {
            this.TableItems = await ApiSlider.GetSliderDisplay(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    created() {
        this.GetSlider();
    }
};
SliderList = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], SliderList);
export default SliderList;

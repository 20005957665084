import { __decorate } from "tslib";
import { Component, Vue, Ref } from "vue-property-decorator";
import DataProfile from "@/data/Profile/DataProfile";
import DataUserEditProfilePage from "@/data/Profile/DataUserEditProfilePage";
import { Getter } from "vuex-class";
import sweetalert from "sweetalert";
import ApiProfile from "@/api/ApiProfile";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
let ProfileEdit = class ProfileEdit extends Vue {
    constructor() {
        super(...arguments);
        this.FormModel = DataUserEditProfilePage.FormModel;
        this.Breadcrumbs = DataUserEditProfilePage.Breadcrumbs;
        this.LeftMenuItems = DataUserEditProfilePage.LeftMenuItems;
        this.SelectSexList = DataProfile.SelectSexList;
        this.CurrentLeftMenuItem = DataUserEditProfilePage.DefaultKeyFromLeftMenu;
    }
    created() {
        DataUserEditProfilePage.ClearFormModel();
        this.FormModel.Name.value = this.GetCurrentSessionUser?.first_name;
        this.FormModel.SecondName.value = this.GetCurrentSessionUser?.second_name;
        this.FormModel.LastName.value = this.GetCurrentSessionUser?.last_name;
        this.FormModel.Phone.value = this.GetCurrentSessionUser?.phone;
        this.FormModel.Birthday.value = this.GetCurrentSessionUser?.birthday;
        this.FormModel.Sex.value = this.GetCurrentSessionUser?.sex;
    }
    OnClickLeftMenuItem(item) {
        if (item.key == "delete") {
            this.OnClickDeleteFromLeftMenuItem();
        }
        else {
            this.CurrentLeftMenuItem = item.key;
        }
    }
    OnClickDeleteFromLeftMenuItem() {
        sweetalert({
            title: "Вы уверены?",
            text: "Вы дейсвительно хотите удалить свой профиль?",
            icon: "warning",
            buttons: ["Нет, Отмена!", "Да, Подтверждаю!"],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiProfile.deleteProfile(this.GetCurrentSessionUser?.user_uuid, this.GetCurrentSessionUser?.system_role, ApiEnter.CurrentSessionUUID);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: "Успешно!",
                        text: "Профиль удален",
                        icon: "success",
                    });
                    this.$router.replace("/");
                }
                else {
                    sweetalert({
                        title: "Ошибка!",
                        text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                        icon: "error",
                    });
                }
            }
        });
    }
    async OnClickChangePassword() {
        const validate = this.ValidateFormsPassword.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        try {
            const response = await ApiProfile.passwordUpdate(this.FormModel.OldPassword.value, this.FormModel.Password.value, ApiEnter.CurrentSessionUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: "Успешно!",
                    text: "Пароль успешно сменен",
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: "Ошибка!",
                    text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                    icon: "error",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: "Ошибка!",
                text: "Во время выполнения запроса, возникла ошибка!",
                icon: "error",
            });
        }
    }
    async OnClickChangeEmail() {
        const validate = this.ValidateFormsEmail.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        try {
            const response = await ApiProfile.emailUpdate(this.FormModel.Email.value, ApiEnter.CurrentSessionUUID);
            console.log("response", response);
            if (typeof response == "boolean") {
                sweetalert({
                    title: "Успешно!",
                    text: "Пароль успешно сменен",
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: "Ошибка!",
                    text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                    icon: "error",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: "Ошибка!",
                text: "Во время выполнения запроса, возникла ошибка!",
                icon: "error",
            });
        }
    }
    async OnClickChangeProfile() {
        const validate = this.ValidateFormsProfile.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        try {
            const response = await ApiProfile.profileUpdate(this.FormModel.Name.value, this.FormModel.SecondName.value, this.FormModel.LastName.value, this.FormModel.Phone.value, this.FormModel.Birthday.value, this.FormModel.Sex.value, ApiEnter.CurrentSessionUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: "Успешно!",
                    text: "Профиль поменян",
                    icon: "success",
                });
            }
            else {
                sweetalert({
                    title: "Ошибка!",
                    text: `Во время выполнения запроса, возникла ошибка: ${response}`,
                    icon: "error",
                });
            }
        }
        catch (e) {
            sweetalert({
                title: "Ошибка!",
                text: "Во время выполнения запроса, возникла ошибка",
                icon: "error",
            });
        }
    }
};
__decorate([
    Ref("ValidateFormsPassword")
], ProfileEdit.prototype, "ValidateFormsPassword", void 0);
__decorate([
    Ref("ValidateFormsProfile")
], ProfileEdit.prototype, "ValidateFormsProfile", void 0);
__decorate([
    Ref("ValidateFormsEmail")
], ProfileEdit.prototype, "ValidateFormsEmail", void 0);
__decorate([
    Getter("getCurrentSessionUser")
], ProfileEdit.prototype, "GetCurrentSessionUser", void 0);
ProfileEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], ProfileEdit);
export default ProfileEdit;

import axios from "axios";
class ApiFaq {
    static async GetFaq(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-faq", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get faq");
        }
    }
    static async GetFaqByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/get-faq-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFaqQuestion(session_uuid, answer, question, priority, publish, category) {
        try {
            const result = await axios.post("/api/admin/create-faq", {
                answer: answer,
                question: question,
                priority: priority,
                publish: publish,
                category: category,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteFaqQuestion(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-faq", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFaqPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-faq-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFaqInfo(answer, question, priority, publish, category, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-faq", {
                uuid: uuid,
                answer: answer,
                question: question,
                priority: priority,
                publish: publish,
                category: category,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async GetFaqCategories(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-faq-categories", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get faq");
        }
    }
    static async GetFaqCategoryByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/get-faq-category-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFaqCategory(session_uuid, name, priority, publish) {
        try {
            const result = await axios.post("/api/admin/create-faq-category", {
                name: name,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteFaqCategory(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-faq-category", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFaqCategoryPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-faq-category-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFaqCategoryInfo(name, priority, publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-faq-category-info", {
                uuid: uuid,
                name: name,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
}
export default ApiFaq;

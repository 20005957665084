import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiSupportFiles from "@/api/ApiSupportFiles";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataFaq from "@/data/AdminPanel/DataFaq";
import dayjs from "dayjs";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let FaqEdit = class FaqEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/cms/files",
                exact: true,
                text: "CMS",
            },
            {
                to: "/cms/files",
                exact: true,
                text: "Support (Files)",
            },
            {
                text: "Categories",
                disabled: true,
            },
        ];
        this.TableHeaders = DataFaq.CategoriesTableHeaders;
        this.TableItems = [];
        this.isOpenDialog = false;
        this.isOpenEditDialog = false;
        this.newCategory = {
            name: "",
            priority: "",
            publish: true,
            created: "",
        };
        this.currentCategory = {
            name: "",
            priority: "",
            publish: true,
            created: "",
            uuid: "",
        };
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    OpenDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        this.newCategory.name = "";
        this.newCategory.priority = "";
        this.newCategory.publish = true;
        this.newCategory.created = "";
    }
    OpenEditDialog() {
        this.isOpenEditDialog = !this.isOpenEditDialog;
    }
    async CreateFaqCategory() {
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const file_uuid = await ApiSupportFiles.CreateFilesCategory(ApiEnter.CurrentSessionUUID, this.newCategory.name, this.newCategory.priority, this.newCategory.publish ? 1 : 0);
            if (file_uuid == undefined || file_uuid.length != 36) {
                await sweetalert({
                    title: "Request error!",
                    text: "Creation error File: " + file_uuid,
                    icon: "info",
                });
                return;
            }
            this.$forceUpdate();
            sweetalert({
                title: "Success!",
                text: `File category has created!`,
                icon: "success",
            }).then(() => this.OpenDialog());
            await this.GetCategories();
        }
    }
    async ChangeFaqCategoryPublish(item) {
        try {
            const response = await ApiSupportFiles.UpdateFilesCategoryPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: "Success!",
                    text: "Faq Category has updated",
                }).then(() => {
                    this.GetCategories();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error") + " " + response,
                    icon: "error",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "error",
            });
        }
    }
    async ChangeFaqCategoryInfo() {
        try {
            const response = await ApiSupportFiles.UpdateFilesCategoryInfo(this.currentCategory.name, this.currentCategory.priority, this.currentCategory.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, this.currentCategory.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.OpenEditDialog();
                    this.GetCategories();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error") + " " + response,
                    icon: "error",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "error",
            });
        }
    }
    async openEditDialog(uuid) {
        this.currentCategory.uuid = uuid;
        const categoryInfo = await ApiSupportFiles.GetFilesCategoryByUUID(ApiEnter.CurrentSessionUUID, uuid);
        if (categoryInfo == undefined) {
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "error",
            });
            return;
        }
        this.OpenEditDialog();
        this.currentCategory.name = categoryInfo.name;
        this.currentCategory.priority = categoryInfo.priority;
        this.currentCategory.publish = categoryInfo.publish;
        this.currentCategory.created = dayjs.tz(categoryInfo.create_at, "Europe/Rome").format("DD.MM.YYYY");
    }
    DeleteCategory(faq_uuid) {
        sweetalert({
            title: this.$t("general.are_you_sure"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiSupportFiles.DeleteFilesCategory(ApiEnter.CurrentSessionUUID, faq_uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "error",
                    });
                    await this.GetCategories();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error") + " " + response,
                        icon: "error",
                    });
                }
            }
        });
    }
    async GetCategories() {
        try {
            this.TableItems = await ApiSupportFiles.GetFilesCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    created() {
        this.GetCategories();
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], FaqEdit.prototype, "GetCurrentSessionUser", void 0);
FaqEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], FaqEdit);
export default FaqEdit;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiSettings from "@/api/ApiSettings";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import dayjs from "dayjs";
import DataEmailNotifications from "@/data/AdminPanel/DataEmailNotifications";
let EmailNotifications = class EmailNotifications extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataEmailNotifications.Breadcrumbs;
        this.TableHeaders = DataEmailNotifications.TableHeaders;
        this.TableItems = [];
    }
    async ChangePublish(item) {
        try {
            const response = await ApiSettings.UpdateEmailNotificationPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.GetEmailNotifications();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async DeleteItem(uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("files.accept_delete"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiSettings.DeleteEmailNotification(ApiEnter.CurrentSessionUUID, uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    await this.GetEmailNotifications();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    async GetEmailNotifications() {
        try {
            this.TableItems = await ApiSettings.GetEmailNotifications(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    formatDate(item) {
        return dayjs.tz(item, "Europe/Rome").format("DD.MM.YYYY HH:mm");
    }
    created() {
        this.GetEmailNotifications();
    }
};
EmailNotifications = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], EmailNotifications);
export default EmailNotifications;

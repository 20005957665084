import { __decorate } from "tslib";
import { Component, Ref, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import OtherSettingsData from "@/data/Shop/OtherSettings/OtherSettingsData";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditor from "@/data/CKeditor/config";
import ApiEnter from "@/api/ApiEnter";
import UtilGeneral from "@/util/Common/UtilGeneral";
import ApiOtherSettings from "@/api/ApiOtherSettings";
import sweetalert from "sweetalert";
let OtherSettings = class OtherSettings extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = OtherSettingsData.Breadcrumbs;
        this.HasLoading = false;
        this.editor = ClassicEditor;
        this.editorConfig = CKEditor.editorConfig(ApiEnter.CurrentSessionUUID);
        this.FormData = null;
        this.PanelBoolean1 = [0];
        this.PanelBoolean2 = [0];
        this.PanelBoolean3 = [0];
    }
    ValidateSeoUrl() {
        if (this.FormData) {
            this.FormData.url = UtilGeneral.TranslitText(this.FormData.url.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
        }
    }
    ValidateSIMPrice() {
        if (this.FormData) {
            this.FormData.SIMPrice = this.FormData.SIMPrice.replace(/[^0-9 .,]/g, "");
        }
    }
    ValidateEShopPrice() {
        if (this.FormData) {
            this.FormData.eshopPrice = this.FormData.eshopPrice.replace(/[^0-9 .,]/g, "");
        }
    }
    async GetSettings() {
        const response = await ApiOtherSettings.GetSettings(ApiEnter.CurrentSessionUUID);
        if (typeof response != "boolean") {
            this.FormData = response;
            this.FormData.eshopPrice = String(response.eshopPrice);
            this.FormData.SIMPrice = String(response.SIMPrice);
            this.FormData.eshopPrice = this.FormData.eshopPrice.split(".").join(",");
            this.FormData.SIMPrice = this.FormData.SIMPrice.split(".").join(",");
        }
        let response_promo = await ApiOtherSettings.GetPromoData(ApiEnter.CurrentSessionUUID);
        if (typeof response_promo != "boolean") {
            if (this.FormData != null) {
                this.FormData.promoIsActive = JSON.parse(response_promo).IsActive;
            }
        }
    }
    async EditSettings() {
        this.HasLoading = true;
        const validate = this.CurrentForm.validate();
        if (validate) {
            const response = await ApiOtherSettings.EditSettings(ApiEnter.CurrentSessionUUID, this.FormData);
            if (response) {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
                this.GetSettings();
                this.$router.back();
            }
        }
        else {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
        this.HasLoading = false;
    }
    created() {
        this.GetSettings();
    }
};
__decorate([
    Ref("form-validate")
], OtherSettings.prototype, "CurrentForm", void 0);
OtherSettings = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], OtherSettings);
export default OtherSettings;

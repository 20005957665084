import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataOrder from "@/data/Shop/Order/DataOrder";
import ApiEnter from "@/api/ApiEnter";
import ApiOrders from "@/api/ApiOrders";
import dayjs from "dayjs";
import { EOrderEnum } from "@/struct/order/EOrderEnum";
import sweetalert from "sweetalert";
let Orders = class Orders extends Vue {
    constructor() {
        super(...arguments);
        this.IsApplyFilter = false;
        this.HasRequest = false;
        this.search = "";
        this.FilterTable = {
            DateFrom: dayjs().subtract(1, "week").startOf("day").format("YYYY-MM-DD HH:mm:ss"),
            DateTo: dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss"),
            TotalFrom: "",
            TotalTo: "",
            OrderStatus: [],
            PaymentStatus: [],
        };
        this.FilterTableAppend = {
            TotalFrom: "",
            TotalTo: "",
            OrderStatus: [],
            PaymentStatus: [],
        };
        this.OrderStatusFilterItems = [
            {
                text: this.$t("orders.new"),
                value: EOrderEnum.OrderStatus.New,
            },
            {
                text: this.$t("orders.in_process"),
                value: EOrderEnum.OrderStatus.InProcess,
            },
            {
                text: this.$t("orders.in_delivery"),
                value: EOrderEnum.OrderStatus.InDelivery,
            },
            {
                text: this.$t("orders.cancelled"),
                value: EOrderEnum.OrderStatus.Cancelled,
            },
        ];
        this.PaymentStatusFilterItems = [
            {
                text: this.$t("orders.paid"),
                value: EOrderEnum.OrderPaymentStatus.Paid,
            },
            {
                text: this.$t("orders.not_paid"),
                value: EOrderEnum.OrderPaymentStatus.NotPaid,
            },
        ];
        this.Breadcrumbs = DataOrder.Breadcrumbs;
        this.TableHeaders = [
            {
                text: this.$t("table_headers.date"),
                value: "create_at",
                width: "15%",
            },
            {
                text: this.$t("table_headers.order"),
                value: "id",
            },
            {
                text: this.$t("table_headers.customer"),
                value: "first_name",
            },
            {
                text: this.$t("table_headers.email"),
                value: "email",
            },
            {
                text: this.$t("table_headers.fiscal_code"),
                value: "fiscal_code",
            },
            {
                text: this.$t("table_headers.phone_number"),
                value: "phone",
            },
            {
                text: this.$t("table_headers.total"),
                value: "amount",
                filter: value => {
                    if (this.FilterTableAppend.TotalFrom && this.FilterTableAppend.TotalTo) {
                        return value >= Number(this.FilterTableAppend.TotalFrom) && value <= Number(this.FilterTableAppend.TotalTo);
                    }
                    if (this.FilterTableAppend.TotalFrom && !this.FilterTableAppend.TotalTo) {
                        return value >= Number(this.FilterTableAppend.TotalFrom);
                    }
                    if (!this.FilterTableAppend.TotalFrom && this.FilterTableAppend.TotalTo) {
                        return value <= Number(this.FilterTableAppend.TotalTo);
                    }
                    return true;
                },
            },
            {
                text: this.$t("table_headers.order_status"),
                value: "status",
                filter: value => {
                    if (this.FilterTableAppend.OrderStatus.length) {
                        const items = this.FilterTableAppend.OrderStatus.filter((item) => {
                            return item === value;
                        });
                        return !!items.length;
                    }
                    return true;
                },
            },
            {
                text: this.$t("table_headers.payment_status"),
                value: "payment_status",
                filter: value => {
                    if (this.FilterTableAppend.PaymentStatus.length) {
                        const items = this.FilterTableAppend.PaymentStatus.filter((item) => {
                            return item === value;
                        });
                        return !!items.length;
                    }
                    return true;
                },
            },
        ];
        this.TableItems = [];
        this.dateFrom = null;
        this.dateTo = null;
        this.menuDateFrom = false;
        this.menuDateTo = false;
        this.isShowFilter = false;
    }
    created() {
        this.LoadOrders();
    }
    SetFilter() {
        this.IsApplyFilter = true;
        this.FilterTableAppend.TotalFrom = this.FilterTable.TotalFrom;
        this.FilterTableAppend.TotalTo = this.FilterTable.TotalTo;
        this.FilterTableAppend.OrderStatus = this.FilterTable.OrderStatus;
        this.FilterTableAppend.PaymentStatus = this.FilterTable.PaymentStatus;
        this.LoadOrders();
    }
    ResetFilter() {
        this.IsApplyFilter = false;
        this.FilterTableAppend.TotalFrom = "";
        this.FilterTableAppend.TotalTo = "";
        this.FilterTableAppend.OrderStatus = [];
        this.FilterTableAppend.PaymentStatus = [];
        this.FilterTable.DateFrom = dayjs().subtract(1, "week").startOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.FilterTable.DateTo = dayjs().endOf("day").format("YYYY-MM-DD HH:mm:ss");
        this.LoadOrders();
    }
    async LoadOrders() {
        this.HasRequest = true;
        const response = await ApiOrders.GetOrdersList(ApiEnter.CurrentSessionUUID, this.FilterTable.DateFrom, this.FilterTable.DateTo);
        if (response) {
            this.TableItems = response;
        }
        this.HasRequest = false;
    }
    ValidateAmount(amount) {
        return amount.toFixed(2).replace(".", ",") + "€";
    }
    ValidatePaymentStatus(value, type) {
        const Enums = EOrderEnum.OrderPaymentStatus;
        if (type == "color") {
            if (value === Enums.NotPaid)
                return "grey darken-1 text-white";
            if (value === Enums.Refunded)
                return "red";
            if (value === Enums.PartiallyRefunded)
                return "pink";
            if (value === Enums.Paid)
                return "green";
        }
        if (type == "text") {
            if (value === Enums.NotPaid)
                return this.$t("orders.not_paid");
            if (value === Enums.Refunded)
                return this.$t("orders.refunded");
            if (value === Enums.PartiallyRefunded)
                return this.$t("orders.partially_refunded");
            if (value === Enums.Paid)
                return this.$t("orders.paid");
        }
    }
    ValidateOrderStatus(value, type) {
        const Enums = EOrderEnum.OrderStatus;
        if (type == "color") {
            if (value === Enums.NotFinished)
                return "grey darken-1 text-white";
            if (value === Enums.New)
                return "yellow darken-2 text-black";
            if (value === Enums.InProcess)
                return "orange";
            if (value === Enums.InDelivery)
                return "light-blue";
            if (value === Enums.Delivered)
                return "blue";
            if (value === Enums.Cancelled)
                return "red";
            if (value === Enums.Finished)
                return "green";
            if (value === Enums.PartiallyFinished)
                return "teal darken-2 text-black";
        }
        if (type == "text") {
            if (value === Enums.NotFinished)
                return this.$t("orders.not_finished");
            if (value === Enums.New)
                return this.$t("orders.new");
            if (value === Enums.InProcess)
                return this.$t("orders.in_process");
            if (value === Enums.InDelivery)
                return this.$t("orders.in_delivery");
            if (value === Enums.Delivered)
                return this.$t("orders.delivered");
            if (value === Enums.Cancelled)
                return this.$t("orders.cancelled");
            if (value === Enums.Finished)
                return this.$t("orders.finished");
            if (value === Enums.PartiallyFinished)
                return this.$t("orders.partially_finished");
        }
    }
    formatDate(item) {
        return dayjs.tz(item, "Europe/Rome").format("DD-MM-YYYY (HH:mm:s)");
    }
    async UpdateStatusOrders() {
        this.HasRequest = true;
        this.TableItems = [];
        const response = await ApiOrders.RefreshOrderStatuses(ApiEnter.CurrentSessionUUID);
        if (!response) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error") + response,
                icon: "error",
            });
        }
        await this.LoadOrders();
        this.HasRequest = false;
    }
};
Orders = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], Orders);
export default Orders;

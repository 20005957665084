import axios from "axios";
export default class ApiDelivery {
    static async UploadCSVFile(session_uuid, file, loadToDb) {
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("loadToDb", String(loadToDb));
            const result = await axios.post("/api/admin/upload-csv-delivery-files", formData, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "Content-Type": "multipart/form-data;",
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}

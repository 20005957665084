import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import ApiEnter from "@/api/ApiEnter";
import ApiOfferDetails from "@/api/ApiOfferDetails";
import sweetalert from "sweetalert";
import { EActivationPriceFor, EPriceFor } from "@/struct/offer-details/IOfferDetails";
let EditOfferDetails = class EditOfferDetails extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/",
                exact: true,
                text: "CMS",
            },
            {
                to: "/",
                exact: true,
                text: "Offer details",
            },
            {
                text: "Edit",
                disabled: true,
            },
        ];
        this.currentOfferDetailsUUID = null;
        this.data = {
            price: "",
            priceFor: 1,
            activationPrice: "",
            activationPriceFor: 1,
            nameEn: "",
            nameIt: "",
            otherTextEn: "",
            otherTextIt: "",
            otherTextActivationPriceEn: "",
            otherTextActivationPriceIt: "",
        };
        this.bssInfo = {
            id: "",
            title: "",
            renewalPrice: "",
            activationPrice: "",
        };
        this.dataTextForPrice = [
            {
                text: "al mese",
                value: EPriceFor.AlMese,
            },
            {
                text: "primo mese",
                value: EPriceFor.PrimoMese,
            },
            {
                text: "due mesi inclusi",
                value: EPriceFor.DueMesiInclusi,
            },
            {
                text: "other",
                value: EPriceFor.Other,
            },
        ];
        this.dataTextForActivationPrice = [
            {
                text: "costo di attivazione",
                value: EActivationPriceFor.CostoDiAttivazione,
            },
            {
                text: "sconto all’attivazione",
                value: EActivationPriceFor.ScontoAllAttivazione,
            },
            {
                text: "nessun costo di attivazione",
                value: EActivationPriceFor.NessunCostoDiAttivazione,
            },
            {
                text: "other",
                value: EActivationPriceFor.Other,
            },
        ];
    }
    async GetOfferByUUID() {
        const response = await ApiOfferDetails.GetOfferDetailsByUUID(ApiEnter.CurrentSessionUUID, this.currentOfferDetailsUUID);
        if (typeof response != "boolean") {
            this.data.otherTextActivationPriceEn = response.other_text_two_en || "";
            this.data.otherTextActivationPriceIt = response.other_text_two_it || "";
            this.data.otherTextEn = response.other_text_one_en || "";
            this.data.otherTextIt = response.other_text_one_it || "";
            this.bssInfo.id = response.bss_details_id;
            this.bssInfo.title = response.bss_details_title;
            this.bssInfo.renewalPrice = response.bss_details_renewal_price;
            this.bssInfo.activationPrice = response.bss_details_activation_price;
            this.data.priceFor = response.price_for;
            this.data.activationPriceFor = response.act_price_for;
            this.data.price = response.price_eur ? String(response.price_eur) : "";
            this.data.activationPrice = response.act_price_eur ? String(response.act_price_eur) : "";
            this.data.nameEn = response.name_en || "";
            this.data.nameIt = response.name_it || "";
        }
    }
    async UpdateOfferDetails() {
        const otherTexts = {
            other_text_one_en: this.data.otherTextEn,
            other_text_one_it: this.data.otherTextIt,
            other_text_two_en: this.data.otherTextActivationPriceEn,
            other_text_two_it: this.data.otherTextActivationPriceIt,
        };
        const response = await ApiOfferDetails.EditOfferDetails(ApiEnter.CurrentSessionUUID, this.currentOfferDetailsUUID, this.data.priceFor, this.data.activationPriceFor, this.data.nameIt, this.data.nameEn, this.data.price, this.data.activationPrice, JSON.stringify(otherTexts));
        if (response) {
            sweetalert({
                title: "Success updated",
                icon: "success",
            }).then(() => {
                this.$router.push("/admin/offer-details/list");
            });
        }
        else {
            sweetalert({
                title: "Error with updated",
                icon: "error",
            });
        }
    }
    created() {
        this.currentOfferDetailsUUID = this.$route.params.uuid;
        this.GetOfferByUUID();
    }
};
EditOfferDetails = __decorate([
    Component({
        components: { PageHeader, StandartTemplate },
    })
], EditOfferDetails);
export default EditOfferDetails;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ApiCompanyEmployee from "@/api/ApiCompanyEmployee";
import ApiEnter from "@/api/ApiEnter";
import ApiCompany from "@/api/ApiCompany";
import EUserEnum from "@/struct/user/EUserEnum";
import ECompanyEmployeeEnum from "@/struct/company/ECompanyEmployeeEnum";
import sweetalert from "sweetalert";
var CompanyEmployeeRole = ECompanyEmployeeEnum.CompanyEmployeeRole;
var CompanyEmployeeStatus = ECompanyEmployeeEnum.CompanyEmployeeStatus;
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
let CompanyEmployeeList = class CompanyEmployeeList extends Vue {
    created() {
        this.CurrentEmployeeList = undefined;
        this.Breadcrumbs = undefined;
        this.LoadTable();
        // Вызываем на случай - если был переход, и изменения GetCurrentCompanyItem не происходит.
        this.OnGetCurrentCompanyItemChanged();
    }
    async LoadTable() {
        this.CurrentEmployeeList = await ApiCompanyEmployee.GetEmployeeListFromSessionUUID(ApiEnter.CurrentSessionUUID, ApiCompany.CurrentCompanyUUID);
        this.$forceUpdate();
    }
    OnGetCurrentCompanyItemChanged() {
        if (this.Breadcrumbs == undefined) {
            this.Breadcrumbs = [
                {
                    to: "/",
                    text: "Главная",
                },
                {
                    to: "/company/list",
                    text: "Компании",
                },
                {
                    to: "/company/dashboard/" + this.$route.params.company_uuid,
                    text: "...",
                },
                {
                    disabled: true,
                    to: "/company/employee/list/" + this.$route.params.company_uuid,
                    text: "Управление сотрудниками",
                },
            ];
            this.Breadcrumbs[2].text = "(" + this.GetCurrentCompanyItem?.id + ") " + this.GetCurrentCompanyItem?.name;
        }
    }
    mounted() {
        document.title = "Сотрудники компании: " + this.GetCurrentCompanyItem?.name;
    }
    GetClassBadgeFromEmployeeUserStatus(employee) {
        switch (employee.user_status) {
            case EUserEnum.UserStatus.Active:
                return "success";
            case EUserEnum.UserStatus.Created:
                return "info text-dark";
            case EUserEnum.UserStatus.Banned:
                return "danger";
            case EUserEnum.UserStatus.Deleted:
                return "dark";
            case EUserEnum.UserStatus.NoActive:
                return "warning text-dark";
        }
        return "primary";
    }
    GetTextBadgeFromEmployeeStatus(employee) {
        switch (employee.status) {
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.Active:
                return "Активен";
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.NoActive:
                return "Отключен";
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.Dismissed:
                return "Уволен";
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.Deleted:
                return "Удален";
        }
        return "info";
    }
    GetClassBadgeFromEmployeeStatus(employee) {
        switch (employee.status) {
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.Active:
                return "success";
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.NoActive:
                return "warning";
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.Dismissed:
                return "danger";
            case ECompanyEmployeeEnum.CompanyEmployeeStatus.Deleted:
                return "info";
        }
        return "info";
    }
    SetEmployeeRole(employee, role) {
        sweetalert({
            title: "Вы уверены?",
            text: "Вы дейсвительно хотите установить роль: " + ECompanyEmployeeEnum.CompanyEmployeeRole[role],
            icon: "warning",
            buttons: ["Нет, Отмена!", "Да, Подтверждаю!"],
        }).then(async (isConfirm) => {
            if (isConfirm) {
                try {
                    await ApiCompanyEmployee.SetEmployeeRoleFromSessionUUID(ApiEnter.CurrentSessionUUID, ApiCompany.CurrentCompanyUUID, employee.uuid, role);
                    await this.LoadTable();
                }
                catch (e) {
                    console.error(e);
                    sweetalert({
                        title: "Ошибка!",
                        text: "Во время выполнения запроса, возникла ошибка!",
                        icon: "error",
                    });
                }
            }
        });
    }
    SetEmployeeStatus(employee, status) {
        sweetalert({
            title: "Вы уверены?",
            text: "Вы дейсвительно хотите установить статус: " + ECompanyEmployeeEnum.CompanyEmployeeStatus[status],
            icon: "warning",
            buttons: ["Нет, Отмена!", "Да, Подтверждаю!"],
        }).then(async (isConfirm) => {
            if (isConfirm) {
                try {
                    await ApiCompanyEmployee.SetEmployeeStatusFromSessionUUID(ApiEnter.CurrentSessionUUID, ApiCompany.CurrentCompanyUUID, employee.uuid, status);
                    await this.LoadTable();
                }
                catch (e) {
                    console.error(e);
                    sweetalert({
                        title: "Ошибка!",
                        text: "Во время выполнения запроса, возникла ошибка!",
                        icon: "error",
                    });
                }
            }
        });
    }
    DeleteEmployee(employee) {
        sweetalert({
            title: "Вы уверены?",
            text: "Вы дейсвительно хотите удалить сотрудника?",
            icon: "warning",
            buttons: ["Нет, Отмена!", "Да, Подтверждаю!"],
        }).then(async (isConfirm) => {
            if (isConfirm) {
                try {
                    await ApiCompanyEmployee.DeleteEmployeeFromSessionUUID(ApiEnter.CurrentSessionUUID, ApiCompany.CurrentCompanyUUID, employee.uuid);
                    await this.LoadTable();
                }
                catch (e) {
                    console.error(e);
                    sweetalert({
                        title: "Ошибка!",
                        text: "Во время выполнения запроса, возникла ошибка!",
                        icon: "error",
                    });
                }
            }
        });
    }
    GetTextBadgeFromEmployeeUserStatus(employee) {
        return EUserEnum.UserStatus[employee.user_status];
    }
    EmployeeRoleHasLock(employee) {
        if (employee.role == CompanyEmployeeRole.Owner) {
            return true;
        }
        if (employee.user_uuid == this.GetCurrentSessionUser.user_uuid) {
            return true;
        }
        return false;
    }
    GetTextFromEmployeeRole(employee) {
        switch (employee.role) {
            case ECompanyEmployeeEnum.CompanyEmployeeRole.Owner:
                return "Владелец";
            case ECompanyEmployeeEnum.CompanyEmployeeRole.Admin:
                return "Админ";
            case ECompanyEmployeeEnum.CompanyEmployeeRole.Driver:
                return "Водитель";
            case ECompanyEmployeeEnum.CompanyEmployeeRole.Manager:
                return "Менеджер";
            case ECompanyEmployeeEnum.CompanyEmployeeRole.Accountant:
                return "Бухгалтер";
        }
        return "Не Известно";
    }
    GetTextFromEmployeeStatus(employee) {
        switch (employee.status) {
            case CompanyEmployeeStatus.Active:
                return "Активный";
            case CompanyEmployeeStatus.Deleted:
                return "Удаленный";
            case CompanyEmployeeStatus.NoActive:
                return "Отключенный";
            case CompanyEmployeeStatus.Dismissed:
                return "Уволенный";
        }
        return "Не Известно";
    }
    OnClickCreateEmployee() {
        sweetalert({
            title: "Добавление сотрудника",
            text: "Введите Email нового сотрудника:",
            content: {
                element: "input",
                attributes: {
                    placeholder: "Пример: vasya@mail.ru",
                    type: "email",
                },
            },
        }).then(async (result) => {
            if (result == null) {
                return;
            }
            if (result.trim().length == 0) {
                sweetalert({
                    title: "Ошибка!",
                    text: "Вы не чего не ввели, сотрудник не добавлен!",
                    icon: "error",
                });
                return;
            }
            if (!result.match(/^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)) {
                sweetalert({
                    title: "Ошибка!",
                    text: "Вы ввели не корректный Email!",
                    icon: "error",
                });
                return;
            }
            const response = await ApiCompanyEmployee.CreateEmployeeFromSessionUUID(ApiEnter.CurrentSessionUUID, ApiCompany.CurrentCompanyUUID, result);
            if (response.length != 36) {
                sweetalert({
                    title: "Ошибка!",
                    text: "Во время запроса, возникла ошибка: " + response,
                    icon: "error",
                });
            }
            await this.LoadTable();
        });
    }
};
__decorate([
    Getter("getCurrentCompanyItem")
], CompanyEmployeeList.prototype, "GetCurrentCompanyItem", void 0);
__decorate([
    Getter("getCurrentSessionUser")
], CompanyEmployeeList.prototype, "GetCurrentSessionUser", void 0);
CompanyEmployeeList = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
        watch: {
            GetCurrentCompanyItem: "OnGetCurrentCompanyItemChanged",
        },
    })
], CompanyEmployeeList);
export default CompanyEmployeeList;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import Editor from "@tinymce/tinymce-vue";
import ApiStores from "@/api/ApiStores";
let EditStore = class EditStore extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/cms/stores/list",
                exact: true,
                disabled: true,
                text: "CMS",
            },
            {
                to: "/cms/stores/list",
                exact: true,
                text: "Stores List",
            },
            {
                text: "Create Store",
                disabled: true,
            },
        ];
        this.HasLoading = false;
        this.newStore = {
            active: true,
            name: "",
            address: "",
            latitude: "",
            longitude: "",
            description: null,
        };
    }
    initMaps() {
        const google = window.google;
        if (!google)
            return;
        let markerPositions = null;
        if (this.newStore.latitude !== "" && this.newStore.longitude !== "") {
            markerPositions = new google.maps.LatLng(this.newStore.latitude, this.newStore.longitude);
        }
        else {
            markerPositions = new google.maps.LatLng(45.464183, 9.189595);
        }
        let map = new google.maps.Map(document.querySelector("#map"), {
            zoom: 10,
            center: markerPositions,
        });
        let marker = new google.maps.Marker({
            position: markerPositions,
            map,
            draggable: true,
        });
        google.maps.event.addListener(map, "click", event => {
            marker.setPosition(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
            map.panTo(new google.maps.LatLng(event.latLng.lat(), event.latLng.lng()));
            this.newStore.latitude = event.latLng.lat();
            this.newStore.longitude = event.latLng.lng();
        });
        google.maps.event.addListener(marker, "dragend", () => {
            const position = marker.getPosition();
            marker.setPosition(new google.maps.LatLng(position.lat(), position.lng()));
            map.panTo(new google.maps.LatLng(position.lat(), position.lng()));
            this.newStore.latitude = position.lat();
            this.newStore.longitude = position.lng();
        });
        window.marker = marker;
        window.map = map;
        window.setMarker = (lat, lng) => {
            marker.setPosition(new google.maps.LatLng(lat, lng));
            map.panTo(new google.maps.LatLng(lat, lng));
        };
    }
    async GetCoordinates() {
        const response = await ApiStores.GetCoordinatesAddress(ApiEnter.CurrentSessionUUID, this.newStore.address);
        if (typeof response != "boolean") {
            this.newStore.longitude = response.longitude;
            this.newStore.latitude = response.latitude;
            window.setMarker(response.latitude, response.longitude);
        }
        else {
            sweetalert({
                title: "Error request",
                text: "Error with get address, try different address",
            });
        }
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        try {
            const response = await ApiStores.UpdateStore(ApiEnter.CurrentSessionUUID, this.newStore.address, this.newStore.longitude, this.newStore.latitude, this.newStore.name, this.newStore.active ? 1 : 0, this.CurrentStoreUUID);
            if (response) {
                sweetalert({
                    title: "Success update store",
                    icon: "success",
                }).then(() => {
                    this.$router.back();
                });
            }
        }
        catch (e) {
            sweetalert({
                title: "Error with update new store",
            });
        }
        this.HasLoading = false;
    }
    async LoadTable() {
        const response = await ApiStores.GetStoreByUUID(ApiEnter.CurrentSessionUUID, this.CurrentStoreUUID);
        if (typeof response != "boolean") {
            this.newStore.latitude = response.latitude;
            this.newStore.longitude = response.longitude;
            this.newStore.name = response.store_name;
            this.newStore.address = response.address;
            this.newStore.description = response.instructions || "";
            this.newStore.active = response.status;
            this.initMaps();
        }
        else {
            sweetalert({
                title: "Error to get store",
            });
        }
    }
    created() {
        this.CurrentStoreUUID = this.$route.params.store_uuid;
        console.log("this.CurrentStoreUUID", this.CurrentStoreUUID);
        this.LoadTable();
    }
};
EditStore = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, Editor },
    })
], EditStore);
export default EditStore;

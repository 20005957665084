const state = {
    CurrentTenant: undefined,
};
const getters = {
    getCurrentTenant(state) {
        return state.CurrentTenant;
    },
    haveCurrentTenant(state) {
        return state.CurrentTenant !== undefined;
    },
};
const mutations = {
    setCurrentTenant(state, payload) {
        state.CurrentTenant = payload;
    },
};
const actions = {
    async doUpdateCurrentTenant(ctx) {
        ctx.commit("setCurrentTenant", null);
        return;
    },
};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import ApiStores from "@/api/ApiStores";
import DataStores from "@/data/CMS/DataStores";
import dayjs from "dayjs";
let StoresList = class StoresList extends Vue {
    constructor() {
        super(...arguments);
        this.TableHeaders = DataStores.TableHeaders;
        this.Breadcrumbs = DataStores.Breadcrumbs;
        this.TableItems = [];
        this.Search = "";
    }
    async ChangePublish(item) {
        try {
            const response = await ApiStores.ChangeStorePublish(ApiEnter.CurrentSessionUUID, item.uuid, item.status ? 1 : 0);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.LoadTable();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async LoadTable() {
        try {
            const response = await ApiStores.GetStoresList(ApiEnter.CurrentSessionUUID);
            if (typeof response != "boolean") {
                this.TableItems = response;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    async DeleteStore(uuid) {
        sweetalert({
            title: "Are you sure?",
            text: "Do you want delete this store?",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            console.log("isConfirm", isConfirm);
            if (isConfirm == true) {
                const response = await ApiStores.DeleteStore(ApiEnter.CurrentSessionUUID, uuid);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    this.LoadTable();
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    FormatDate(date) {
        return dayjs.tz(date, "Europe/Rome").format("DD-MM-YYYY (HH:mm:s)");
    }
    created() {
        this.LoadTable();
    }
};
StoresList = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], StoresList);
export default StoresList;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import ApiEnter from "@/api/ApiEnter";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import ApiShopOptions from "@/api/ApiShopOptions";
import sweetalert from "sweetalert";
import DataOptions from "@/data/AdminPanel/DataOptions";
import dayjs from "dayjs";
import CKEditorConfig from "@/data/CKeditor/config";
import ApiAdmin from "@/api/ApiAdmin";
import UtilGeneral from "@/util/Common/UtilGeneral";
let OptionsEdit = class OptionsEdit extends Vue {
    constructor() {
        super(...arguments);
        this.editor = ClassicEditor;
        this.Breadcrumbs = DataOptions.BreadcrumbsEdit;
        this.editorConfig = CKEditorConfig.editorConfig(ApiEnter.CurrentSessionUUID);
        this.PeriodTypes = ["al ora", "al giorno", "alla settimana", "al mese", "al anno"];
        this.MeasureTypes = ["SMS", "Giga", "SIM", "Minuti", "Pezzo", "Servizio"];
        this.newOption = {
            name: "",
            priority: "",
            publish: true,
            type: "",
            price: "",
            period: "",
            value: "",
            measure: "",
            connect: "",
            link_for_button: "",
            limits: "",
            overview: "",
            note: "",
            bss: "",
            seo_title: "",
            seo_description: "",
            seo_keywords: "",
            seo_url: "",
            created: "",
            active: "",
            tariff_overview: "",
            prospects_info: null,
            prospects_info_name: "",
            contract: null,
            contract_name: ""
        };
        this.HasLoading = false;
    }
    async DownloadProspectsInfo() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOption.prospects_info_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOption.prospects_info_name.split("/")[8];
            a.click();
        });
    }
    async DownloadContract() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newOption.contract_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newOption.contract_name.split("/")[8];
            a.click();
        });
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    ValidateSeoUrl() {
        this.newOption.seo_url = this.Translit(this.newOption.seo_url.replace(/[. ,$@!^()'*]+/g, "-"));
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        try {
            const response = await ApiShopOptions.UpdateOptionInfo(this.newOption.name, this.newOption.priority, this.newOption.publish ? 1 : 0, this.newOption.type, this.newOption.price, this.newOption.period, this.newOption.value, this.newOption.measure, this.newOption.connect, this.newOption.link_for_button, this.newOption.limits, this.newOption.overview, this.newOption.note, this.newOption.bss, this.newOption.seo_title, this.newOption.seo_description, this.newOption.seo_keywords, this.newOption.seo_url, this.newOption.active === "active" ? 1 : 0, this.newOption.tariff_overview, this.newOption.prospects_info != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOption.prospects_info, this.newOption.prospects_info_name)) : "", this.newOption.contract != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newOption.contract, this.newOption.contract_name)) : "", ApiEnter.CurrentSessionUUID, this.CurrentOptionUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success"
                }).then(() => {
                    this.$router.go(-1);
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info"
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info"
            });
        }
        this.HasLoading = false;
    }
    async DoLoadForm() {
        const optionInfo = await ApiShopOptions.GetOptionByUUID(ApiEnter.CurrentSessionUUID, this.CurrentOptionUUID);
        if (optionInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info"
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.newOption.name = optionInfo.name;
        this.newOption.priority = optionInfo.priority;
        this.newOption.publish = optionInfo.publish;
        this.newOption.type = optionInfo.type;
        this.newOption.price = optionInfo.price;
        this.newOption.period = optionInfo.period;
        this.newOption.value = optionInfo.value;
        this.newOption.measure = optionInfo.measure;
        this.newOption.connect = optionInfo.connect;
        this.newOption.link_for_button = optionInfo.link_for_button;
        this.newOption.limits = optionInfo.limits;
        this.newOption.overview = optionInfo.overview;
        this.newOption.note = optionInfo.note;
        this.newOption.bss = optionInfo.bss;
        this.newOption.seo_title = optionInfo.seo_title;
        this.newOption.seo_description = optionInfo.seo_description;
        this.newOption.seo_keywords = optionInfo.seo_keywords;
        this.newOption.seo_url = optionInfo.seo_url;
        this.newOption.active = optionInfo.active === 1 ? "active" : "non_active";
        this.newOption.tariff_overview = optionInfo.tariff_overview;
        this.newOption.prospects_info = optionInfo.prospects_info ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, optionInfo.prospects_info).then(response => {
            this.newOption.prospects_info = new File([new Blob([response.data])], optionInfo.prospects_info.split("/")[8]);
        }) : null;
        this.newOption.prospects_info_name = optionInfo.prospects_info;
        this.newOption.contract = optionInfo.contract ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, optionInfo.contract).then(response => {
            this.newOption.contract = new File([new Blob([response.data])], optionInfo.contract.split("/")[8]);
        }) : null;
        this.newOption.contract_name = optionInfo.contract;
        this.newOption.created = dayjs.tz(optionInfo.create_at, "Europe/Rome").format("DD.MM.YYYY HH:mm");
        this.$forceUpdate();
    }
    mounted() {
        this.CurrentOptionUUID = this.$route.params.option_uuid;
        this.DoLoadForm();
    }
};
OptionsEdit = __decorate([
    Component({
        components: { StandartTemplate, PageHeader, CKEditor }
    })
], OptionsEdit);
export default OptionsEdit;

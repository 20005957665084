import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
let LanguageSwitcher = class LanguageSwitcher extends Vue {
};
LanguageSwitcher = __decorate([
    Component({
        components: {},
    })
], LanguageSwitcher);
export default LanguageSwitcher;

import i18n from "@/i18n/index.ts";
export default class DataOffersCategories {
}
DataOffersCategories.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/shop/offers",
        exact: true,
        text: i18n.t("breadcrumbs.shop"),
    },
    {
        to: "/shop/offers",
        text: i18n.t("breadcrumbs.offers"),
        disabled: true,
    },
];
DataOffersCategories.TableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "priority",
    },
    {
        text: i18n.t("table_headers.category"),
        value: "name",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];

export default class DataDelivery {
}
DataDelivery.DeliveryParseHeaders = [
    {
        text: "Order id",
        value: "id",
    },
    {
        text: "Reason",
        value: "reason",
    },
];

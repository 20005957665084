import UtilFormValidation from "@/util/Form/UtilFormValidation";
import EUserEnum from "@/struct/user/EUserEnum";
import i18n from "@/i18n/index.ts";
class DataUserEditProfilePage {
    static ClearFormModel() {
        for (const formItem in DataUserEditProfilePage.FormModel) {
            DataUserEditProfilePage.FormModel[formItem].value = DataUserEditProfilePage.FormModel[formItem].default;
        }
    }
}
DataUserEditProfilePage.OnValidationConfirmEmailRules = [(value) => value != DataUserEditProfilePage.FormModel.EmailRepeat.value || "Email не совпадает"];
DataUserEditProfilePage.OnValidationConfirmPasswordRules = [() => DataUserEditProfilePage.FormModel.Password.value != DataUserEditProfilePage.FormModel.PasswordRepeat.value || "Пароли не совпадают"];
DataUserEditProfilePage.DefaultKeyFromLeftMenu = "profile";
DataUserEditProfilePage.DeleteKeyFromLeftMenu = "delete";
DataUserEditProfilePage.LeftMenuItems = [
    {
        icon: "fas fa-user-alt",
        label: "Edit profile",
        key: DataUserEditProfilePage.DefaultKeyFromLeftMenu,
    },
    {
        icon: "fas fa-lock",
        label: "Change password",
        key: "password",
    },
    {
        icon: "fas fa-envelope",
        label: "Change email",
        key: "email",
    },
    {
        icon: "fas fa-question",
        label: "Change status",
        key: "change-status",
    },
    {
        icon: "fas fa-user-tag",
        label: "Change role",
        key: "change-role",
    },
    {
        icon: "fas fa-trash",
        label: "Delete profile",
        key: DataUserEditProfilePage.DeleteKeyFromLeftMenu,
    },
];
DataUserEditProfilePage.Breadcrumbs = [
    {
        to: "/",
        text: "Home",
    },
    {
        to: "/admin/users",
        text: "User management",
    },
    {
        disabled: true,
        to: "/admin/edit-user",
        text: "Edit user",
    },
];
DataUserEditProfilePage.FormModel = {
    Email: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 40,
        rules: [...UtilFormValidation.OnValidationEmailRules, ...UtilFormValidation.CreateOnMaxValueLengthRules(40)],
    },
    EmailRepeat: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 40,
        rules: [...UtilFormValidation.OnValidationEmailRules, ...UtilFormValidation.CreateOnMaxValueLengthRules(40)],
    },
    OldPassword: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
    Password: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
    PasswordRepeat: {
        value: "",
        default: "",
        empty: true,
        focused: false,
        valid: false,
        require: true,
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
};
DataUserEditProfilePage.FormUserData = {
    Name: {
        name: i18n.t("user_management.first_name"),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(2, true), ...UtilFormValidation.CreateOnMaxValueLengthRules(24)],
    },
    LastName: {
        name: i18n.t("user_management.last_name"),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(2, true), ...UtilFormValidation.CreateOnMaxValueLengthRules(24)],
    },
    Phone: {
        name: i18n.t("user_management.phone_number"),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 15,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(10, true), ...UtilFormValidation.CreateOnMaxValueLengthRules(15)],
    },
};
DataUserEditProfilePage.FormUserEmail = {
    Email: {
        name: i18n.t("user_management.email_user"),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 40,
        rules: [...UtilFormValidation.OnValidationEmailRules, ...UtilFormValidation.CreateOnMaxValueLengthRules(40)],
    },
};
DataUserEditProfilePage.FormUserPassword = {
    Password: {
        name: i18n.t("user_management.new_password"),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
    PasswordRepeat: {
        name: i18n.t("user_management.password_repeat"),
        message: "",
        value: "",
        default: "",
        type: "text",
        maxlength: 24,
        rules: [...UtilFormValidation.OnValidationPasswordRules],
    },
};
DataUserEditProfilePage.FormChangeStatus = {
    Status: {
        name: i18n.t("user_management.change_of_status"),
        message: "",
        value: "",
        default: "",
        type: "select",
        maxlength: 20,
        select: [
            {
                label: i18n.t("user_management.active"),
                value: EUserEnum.UserStatus.Active,
            },
            {
                label: i18n.t("user_management.no_active"),
                value: EUserEnum.UserStatus.NoActive,
            },
        ],
        rules: [],
    },
};
DataUserEditProfilePage.FormChangeRole = {
    Role: {
        name: i18n.t("user_management.role_change"),
        message: "",
        value: "",
        default: "",
        type: "select",
        maxlength: 20,
        select: [
            {
                label: i18n.t("user_management.call_center_agent"),
                value: EUserEnum.SystemRole.NoRole,
            },
            {
                label: i18n.t("user_management.chief_agent"),
                value: EUserEnum.SystemRole.ChiefAgent,
            },
            {
                label: i18n.t("user_management.administrator"),
                value: EUserEnum.SystemRole.Admin,
            },
            {
                label: i18n.t("user_management.super_administrator"),
                value: EUserEnum.SystemRole.SuperAdmin,
            },
        ],
        rules: [],
    },
};
export default DataUserEditProfilePage;

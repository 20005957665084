import axios from "axios";
import ECompanyEmployeeEnum from "@/struct/company/ECompanyEmployeeEnum";
var CompanyEmployeeStatus = ECompanyEmployeeEnum.CompanyEmployeeStatus;
class ApiCompanyEmployee {
    static async GetEmployeeListFromSessionUUID(session_uuid, company_uuid) {
        try {
            const result = await axios.get("/api/employee/get-list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return [];
    }
    static async SetEmployeeRoleFromSessionUUID(session_uuid, company_uuid, employee_uuid, role) {
        try {
            const result = await axios.post("/api/employee/update-role", {
                employee_uuid: employee_uuid,
                role: role,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
    static async SetEmployeeStatusFromSessionUUID(session_uuid, company_uuid, employee_uuid, status) {
        try {
            const method = (status == CompanyEmployeeStatus.NoActive ? "de-" : "") + "active";
            const result = await axios.post("/api/employee/" + method, {
                employee_uuid: employee_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
    static async DeleteEmployeeFromSessionUUID(session_uuid, company_uuid, employee_uuid) {
        try {
            const result = await axios.post("/api/employee/delete", {
                employee_uuid: employee_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
        }
        return false;
    }
    static async CreateEmployeeFromSessionUUID(session_uuid, company_uuid, email) {
        try {
            const result = await axios.post("/api/employee/create", {
                email: email,
                role: 0,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "x-company-uuid": company_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            let response = "unknown";
            if ("response" in e && "data" in e.response) {
                response = e.response.data.response;
            }
            console.error(e);
            return response;
        }
    }
}
export default ApiCompanyEmployee;

import VueI18n from "vue-i18n";
import en from "@/i18n/en.json";
import it from "@/i18n/it.json";
import Vue from "vue";
Vue.use(VueI18n);
const messages = {
    en,
    it,
};
export default new VueI18n({
    locale: "en",
    messages: messages,
});

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import Editor from "@tinymce/tinymce-vue";
import ApiSupportFiles from "@/api/ApiSupportFiles";
import ApiAdmin from "@/api/ApiAdmin";
let FilesCreate = class FilesCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/cms/files",
                exact: true,
                text: "CMS",
            },
            {
                to: "/cms/files",
                exact: true,
                text: "Support (Files)",
            },
            {
                text: "Files",
                disabled: true,
            },
        ];
        this.HasLoading = false;
        this.FaqCategories = [];
        this.newPost = {
            name: "",
            description: "",
            publish: true,
            priority: "",
            file: null,
            category: undefined,
        };
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const file_name = await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newPost.file);
            if (file_name) {
                const support_file_uuid = await ApiSupportFiles.CreateFile(ApiEnter.CurrentSessionUUID, this.newPost.name, this.newPost.description, this.newPost.category, file_name, this.newPost.priority, this.newPost.publish ? 1 : 0);
                if (support_file_uuid == undefined || support_file_uuid.length != 36) {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                    return;
                }
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_created"),
                    icon: "success",
                }).then(() => {
                    this.$forceUpdate();
                    this.newPost.name = "";
                    this.newPost.description = "";
                    this.newPost.priority = "";
                    this.newPost.file = "";
                    this.newPost.publish = true;
                    this.newPost.category = "";
                    this.$router.push(`/cms/files`);
                });
            }
        }
        this.HasLoading = false;
    }
    async GetFaqCategories() {
        try {
            this.FaqCategories = await ApiSupportFiles.GetFilesCategories(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    created() {
        this.GetFaqCategories();
    }
};
FilesCreate = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, Editor },
    })
], FilesCreate);
export default FilesCreate;

import ApiCompany from "@/api/ApiCompany";
import ApiEnter from "@/api/ApiEnter";
const state = {
    CurrentCompanyItem: undefined,
    CurrentCompanyList: undefined,
};
const getters = {
    getCurrentCompanyItem(state) {
        return state.CurrentCompanyItem;
    },
    getCurrentCompanyList(state) {
        return state.CurrentCompanyList;
    },
    haveCurrentCompanyItem(state) {
        return state.CurrentCompanyItem != undefined;
    },
    haveCurrentCompanyList(state) {
        return state.CurrentCompanyList != undefined;
    },
};
const mutations = {
    setCurrentCompanyItem(state, payload) {
        if (payload == null) {
            localStorage.removeItem("x-company-uuid");
        }
        else {
            localStorage.setItem("x-company-uuid", payload.uuid);
        }
        state.CurrentCompanyItem = payload;
    },
    setCurrentCompanyList(state, payload) {
        state.CurrentCompanyList = payload;
    },
};
const actions = {
    async doUpdateCurrentCompanyItem(ctx) {
        const company_uuid = localStorage.getItem("x-company-uuid");
        if (company_uuid != undefined) {
            ctx.dispatch("doSetCurrentCompanyItem", company_uuid);
        }
    },
    async doSetCurrentCompanyItem(ctx, company_uuid) {
        console.log("doSetCurrentCompanyItem: " + company_uuid);
        if (ApiEnter.CurrentSessionUUID == undefined) {
            return;
        }
        ApiCompany.CurrentCompanyUUID = company_uuid;
        const company = await ApiCompany.GetCompanyItemFromSession(ApiEnter.CurrentSessionUUID, company_uuid);
        ctx.commit("setCurrentCompanyItem", company);
    },
    async doUpdateCurrentCompanyList(ctx) {
        if (ApiEnter.CurrentSessionUUID == undefined) {
            return;
        }
        const companyList = await ApiCompany.GetCompanyListFromSession(ApiEnter.CurrentSessionUUID);
        ctx.commit("setCurrentCompanyList", companyList);
    },
};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};

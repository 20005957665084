import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import EUserEnum from "@/struct/user/EUserEnum";
let Home = class Home extends Vue {
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin || this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin);
    }
    created() {
        this.Breadcrumbs = [
            {
                disabled: true,
                to: "/",
                text: this.$t("breadcrumbs.home"),
            },
        ];
        this.OnGetCurrentCompanyItemChanged();
    }
    OnGetCurrentCompanyItemChanged() {
        //
    }
    mounted() {
        document.title = "Home Page";
    }
};
__decorate([
    Getter("getCurrentCompanyList")
], Home.prototype, "GetCurrentCompanyList", void 0);
__decorate([
    Getter("getCurrentCompanyItem")
], Home.prototype, "GetCurrentCompanyItem", void 0);
__decorate([
    Getter("getCurrentSessionUser")
], Home.prototype, "GetCurrentSessionUser", void 0);
Home = __decorate([
    Component({
        components: { PageHeader, StandartTemplate },
        watch: {
            GetCurrentCompanyItem: "OnGetCurrentCompanyItemChanged",
        },
    })
], Home);
export default Home;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
import ApiEnter from "@/api/ApiEnter";
let StandartTemplate = class StandartTemplate extends Vue {
    constructor() {
        super(...arguments);
        this.SystemRole = EUserEnum.SystemRole;
        this.IsLoginOnAdminPanel = false;
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && (this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.Admin || this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin);
    }
    async IsLoggedOn() {
        const token_old = localStorage.getItem("XSessionUUIDOLD");
        if (token_old) {
            this.UserOnLogin = await ApiEnter.GetUserFromSession(token_old);
            this.IsLoginOnAdminPanel = true;
        }
    }
    created() {
        this.IsLoggedOn();
    }
};
__decorate([
    Getter("getCurrentCompanyItem")
], StandartTemplate.prototype, "GetCurrentCompanyItem", void 0);
__decorate([
    Getter("getCurrentSessionUser")
], StandartTemplate.prototype, "GetCurrentSessionUser", void 0);
__decorate([
    Getter("getCurrentCompanyList")
], StandartTemplate.prototype, "GetCurrentCompanyList", void 0);
__decorate([
    Getter("getNewOrdersList")
], StandartTemplate.prototype, "NewOrders", void 0);
StandartTemplate = __decorate([
    Component({
        components: {},
    })
], StandartTemplate);
export default StandartTemplate;

export var EPriceFor;
(function (EPriceFor) {
    EPriceFor[EPriceFor["AlMese"] = 1] = "AlMese";
    EPriceFor[EPriceFor["PrimoMese"] = 2] = "PrimoMese";
    EPriceFor[EPriceFor["DueMesiInclusi"] = 3] = "DueMesiInclusi";
    EPriceFor[EPriceFor["Other"] = 4] = "Other";
})(EPriceFor || (EPriceFor = {}));
export var EActivationPriceFor;
(function (EActivationPriceFor) {
    EActivationPriceFor[EActivationPriceFor["CostoDiAttivazione"] = 1] = "CostoDiAttivazione";
    EActivationPriceFor[EActivationPriceFor["ScontoAllAttivazione"] = 2] = "ScontoAllAttivazione";
    EActivationPriceFor[EActivationPriceFor["NessunCostoDiAttivazione"] = 3] = "NessunCostoDiAttivazione";
    EActivationPriceFor[EActivationPriceFor["Other"] = 4] = "Other";
})(EActivationPriceFor || (EActivationPriceFor = {}));

import i18n from "@/i18n/index.ts";
export default class DataSlider {
}
DataSlider.SliderListBreadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/admin",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/slider/slides",
        text: i18n.t("breadcrumbs.slider"),
        disabled: true,
    },
];
DataSlider.SliderListTableHeaders = [
    {
        text: i18n.t("table_headers.date"),
        value: "create_at",
        width: "20%",
    },
    {
        text: i18n.t("table_headers.slide_title"),
        value: "name",
        width: "65%",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
DataSlider.SliderDisplayTableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "slider_sort",
        width: "12%",
    },
    {
        text: i18n.t("table_headers.source"),
        value: "from",
        width: "12%",
    },
    {
        text: i18n.t("table_headers.title"),
        value: "name",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];

import { __decorate } from "tslib";
import { Component, Ref, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiSlider from "@/api/ApiSlider";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import FormGenerator from "@/components/UI/Form/FormGenerator.vue";
import Editor from "@tinymce/tinymce-vue";
import ApiAdmin from "@/api/ApiAdmin";
let SliderCreate = class SliderCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/faq/question",
                text: "CMS",
            },
            {
                text: "Slider",
                disabled: true,
            },
        ];
        this.newPost = {
            title: "",
            subtitle: "",
            publish: true,
            display_text: true,
            image: null,
            alt: "",
            image_m: null,
            alt_m: "",
            link: "",
        };
        this.HasLoading = false;
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            const slider_uuid = await ApiSlider.CreateSlider(ApiEnter.CurrentSessionUUID, this.newPost.title, this.newPost.subtitle, this.newPost.publish ? 1 : 0, this.newPost.display_text ? 1 : 0, await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newPost.image), this.newPost.alt, await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newPost.image_m), this.newPost.alt_m, this.newPost.link);
            if (slider_uuid == undefined || slider_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => {
                this.$forceUpdate();
                this.newPost.title = "";
                this.newPost.subtitle = "";
                this.newPost.link = "";
                this.newPost.publish = true;
                this.$router.push(`/slider/slides`);
            });
        }
        this.HasLoading = false;
    }
};
__decorate([
    Ref("company-form-generator")
], SliderCreate.prototype, "CompanyFormGenerator", void 0);
SliderCreate = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, FormGenerator, Editor },
    })
], SliderCreate);
export default SliderCreate;

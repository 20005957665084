import ApiOrders from "@/api/ApiOrders";
import ApiEnter from "@/api/ApiEnter";
const state = {
    NewOrdersLength: null,
};
const getters = {
    getNewOrdersList(state) {
        return state.NewOrdersLength;
    },
};
const mutations = {
    setOrdersLength(state, payload) {
        state.NewOrdersLength = payload;
    },
};
const actions = {
    async doGetNewOrdersList(ctx) {
        const response = await ApiOrders.GetNewOrdersLength(ApiEnter.CurrentSessionUUID);
        if (response) {
            ctx.commit("setOrdersLength", response);
        }
    },
};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};

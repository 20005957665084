class DataCompanyList {
}
DataCompanyList.TableHeaders = [
    {
        text: "ID",
        value: "id",
    },
    {
        text: "Название",
        value: "name",
    },
    {
        text: "Состояние",
        value: "status",
    },
];
export default DataCompanyList;

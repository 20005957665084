import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let PageHeader = class PageHeader extends Vue {
    created() {
        this.OnTitleChanged();
    }
    OnTitleChanged() {
        document.title = this.title;
    }
};
__decorate([
    Prop()
], PageHeader.prototype, "title", void 0);
__decorate([
    Prop({ default: "" })
], PageHeader.prototype, "BackUrl", void 0);
PageHeader = __decorate([
    Component({
        components: {},
        watch: {
            title: "OnTitleChanged",
        },
    })
], PageHeader);
export default PageHeader;

import { __decorate } from "tslib";
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import ApiOrders from "@/api/ApiOrders";
import ApiEnter from "@/api/ApiEnter";
import sweetalert from "sweetalert";
let DeliveryTracking = class DeliveryTracking extends Vue {
    constructor() {
        super(...arguments);
        this.Tracking = "";
        this.TrackingLink = "";
    }
    closeModal() {
        return true;
    }
    save() {
        return true;
    }
    async SaveData() {
        const response = await ApiOrders.UpdateDeliveryTracking(ApiEnter.CurrentSessionUUID, this.$route.params.order_uuid, this.Tracking, this.TrackingLink);
        if (response) {
            this.save();
        }
        else {
            sweetalert({
                title: "Error",
                text: "Error to change data",
                icon: "error",
            });
            this.closeModal();
        }
    }
    setData() {
        this.Tracking = this.tracking;
        this.TrackingLink = this.tracking_link;
    }
};
__decorate([
    Prop()
], DeliveryTracking.prototype, "isOpen", void 0);
__decorate([
    Prop()
], DeliveryTracking.prototype, "tracking", void 0);
__decorate([
    Prop()
], DeliveryTracking.prototype, "tracking_link", void 0);
__decorate([
    Emit("closeModal")
], DeliveryTracking.prototype, "closeModal", null);
__decorate([
    Emit("save")
], DeliveryTracking.prototype, "save", null);
__decorate([
    Watch("isOpen")
], DeliveryTracking.prototype, "setData", null);
DeliveryTracking = __decorate([
    Component({})
], DeliveryTracking);
export default DeliveryTracking;

import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let EnterTemplate = class EnterTemplate extends Vue {
    created() {
        //
    }
};
__decorate([
    Prop({ default: "" })
], EnterTemplate.prototype, "TitleText", void 0);
__decorate([
    Prop({ default: "" })
], EnterTemplate.prototype, "SubTitleText", void 0);
EnterTemplate = __decorate([
    Component({
        components: {},
    })
], EnterTemplate);
export default EnterTemplate;

import OffersDetailsList from "@/views/OfferDetails/OffersDetailsList.vue";
import EditOfferDetails from "@/views/OfferDetails/EditOfferDetails.vue";
const routes = [
    {
        path: "/admin/offer-details/list",
        name: "OffersDetailsList",
        component: OffersDetailsList,
    },
    {
        path: "/admin/offer-details/edit/:uuid",
        name: "EditOfferDetails",
        component: EditOfferDetails,
    },
];
export default routes;

import axios from "axios";
export default class ApiOfferDetails {
    static async GetOfferDetailsList(session_uuid) {
        try {
            const response = await axios.get("/api/admin/offer-details/get-list", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return response.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdatePublish(session_uuid, uuid, publish) {
        try {
            const response = await axios.post("/api/admin/offer-details/change-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return response.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetOfferDetailsByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/offer-details/get-offer", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async EditOfferDetails(session_uuid, uuid, price_for, act_price_for, name_it, name_en, price_eur, act_price_eur, other_texts) {
        try {
            const result = await axios.post("/api/admin/offer-details/edit", {
                uuid: uuid,
                price_for: price_for,
                act_price_for: act_price_for,
                name_it: name_it,
                name_en: name_en,
                price_eur: price_eur,
                act_price_eur: act_price_eur,
                other_texts: other_texts,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}

import FeedbackContacts from "@/views/CMS/Feedback/FeedbackContacts.vue";
import FeedbackPartners from "@/views/CMS/Feedback/FeedbackPartners.vue";
import FeedbackContactsView from "@/views/CMS/Feedback/View/FeedbackContactsView.vue";
import FeedbackBecomeAPartnerView from "@/views/CMS/Feedback/View/FeedbackBecomeAPartnerView.vue";
const routes = [
    {
        path: "/cms/feedback/contacts",
        name: "FeedbackContacts",
        component: FeedbackContacts,
    },
    {
        path: "/cms/feedback/partners",
        name: "FeedbackPartners",
        component: FeedbackPartners,
    },
    {
        path: "/cms/feedback/contact/view/:feedback_uuid",
        name: "FeedbackContactsView",
        component: FeedbackContactsView,
    },
    {
        path: "/cms/feedback/partner/view/:feedback_uuid",
        name: "FeedbackBecomeAPartnerView",
        component: FeedbackBecomeAPartnerView,
    },
];
export default routes;

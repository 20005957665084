import { __decorate } from "tslib";
import { Getter } from "vuex-class";
import { Component, Vue } from "vue-property-decorator";
import PreLoading from "@/components/PreLoading.vue";
import LanguageSwitcher from "@/components/LanguageSwitcher.vue";
import "bootstrap/dist/js/bootstrap.min";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "@/assets/style/index.less";
let App = class App extends Vue {
    SetLanguage() {
        const lang = localStorage.getItem("language");
        if (lang) {
            this.$i18n.locale = lang;
            this.$vuetify.lang.current = lang;
        }
    }
    created() {
        this.SetLanguage();
        this.OnChangeRoute(this.$route);
    }
    OnChangeRoute(to) {
        if (this.HaveCurrentTenant == false) {
            return;
        }
        if (this.HaveCurrentSession == false && (to == undefined || to.path.startsWith("/enter/") == false)) {
            this.$router.push("/enter/login");
        }
        else if (this.HaveCurrentSession == true && ((to != undefined && to.path.startsWith("/enter/login")) || location.href.startsWith("/enter/login"))) {
            this.$router.push("/");
        }
    }
    OnChangeCurrentSessionState() {
        if (this.HaveCurrentTenant == false) {
            return;
        }
        if (this.HaveCurrentSession == false && this.$route.path.startsWith("/enter/") == false) {
            this.$router.push("/enter/login");
        }
        else if (this.HaveCurrentSession == true && this.$route.path.startsWith("/enter/") == true) {
            this.$router.push("/");
        }
    }
};
__decorate([
    Getter("haveCurrentTenant")
], App.prototype, "HaveCurrentTenant", void 0);
__decorate([
    Getter("haveCurrentSession")
], App.prototype, "HaveCurrentSession", void 0);
__decorate([
    Getter("getCurrentSessionUser")
], App.prototype, "GetCurrentSessionUser", void 0);
App = __decorate([
    Component({
        components: { LanguageSwitcher, PreLoading },
        watch: {
            $route: "OnChangeRoute",
            GetCurrentSessionUser: "OnChangeCurrentSessionState",
        },
    })
], App);
export default App;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import Editor from "@tinymce/tinymce-vue";
import dayjs from "dayjs";
import ApiAdmin from "@/api/ApiAdmin";
import ApiSettings from "@/api/ApiSettings";
import DataEmailNotifications from "@/data/AdminPanel/DataEmailNotifications";
import UtilGeneral from "@/util/Common/UtilGeneral";
let EmailNotificationsEdit = class EmailNotificationsEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataEmailNotifications.BreadcrumbsEdit;
        this.EmailNotification = {
            publish: true,
            template_id: "",
            template_name: "",
            topic_name: "",
            send_to: "",
            file: null,
            file_name: "",
            update: "",
        };
        this.HasLoading = false;
    }
    async DoLoadForm() {
        const emailNotificationInfo = await ApiSettings.GetEmailNotificationByUUID(ApiEnter.CurrentSessionUUID, this.CurrentUUID);
        if (emailNotificationInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.EmailNotification.publish = emailNotificationInfo.publish;
        this.EmailNotification.template_id = emailNotificationInfo.template_id;
        this.EmailNotification.template_name = emailNotificationInfo.template_name;
        this.EmailNotification.topic_name = emailNotificationInfo.topic_name;
        this.EmailNotification.send_to = emailNotificationInfo.send_to;
        this.EmailNotification.update = dayjs.tz(emailNotificationInfo.update, "Europe/Rome").format("DD.MM.YYYY HH:mm");
        this.EmailNotification.file = emailNotificationInfo.file
            ? ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, emailNotificationInfo.file).then(response => {
                this.EmailNotification.file = new File([new Blob([response.data])], emailNotificationInfo.file.split("/")[8]);
            })
            : null;
        this.EmailNotification.file_name = emailNotificationInfo.file;
        this.$forceUpdate();
    }
    async DownloadFile1() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.EmailNotification.file_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.EmailNotification.file_name.split("/")[8];
            a.click();
        });
    }
    CopyLink() {
        UtilGeneral.CopyLink(this.EmailNotification.file_name);
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        try {
            const response = await ApiSettings.UpdateEmailNotification(this.EmailNotification.template_name, this.EmailNotification.topic_name, this.EmailNotification.send_to, this.EmailNotification.file != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.EmailNotification.file, this.EmailNotification.file_name)) : "", this.EmailNotification.publish, ApiEnter.CurrentSessionUUID, this.CurrentUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.$router.go(-1);
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
        this.HasLoading = false;
    }
    mounted() {
        this.CurrentUUID = this.$route.params.email_uuid;
        this.DoLoadForm();
    }
};
EmailNotificationsEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, Editor },
    })
], EmailNotificationsEdit);
export default EmailNotificationsEdit;

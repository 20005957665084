import i18n from "@/i18n/index.ts";
export default class DataOptions {
}
DataOptions.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/shop/options",
        text: i18n.t("breadcrumbs.extras_and_services"),
        disabled: true,
    },
];
DataOptions.BreadcrumbsCreate = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/shop/options",
        exact: true,
        text: i18n.t("breadcrumbs.extras_and_services"),
    },
    {
        text: i18n.t("breadcrumbs.create"),
        disabled: true,
    },
];
DataOptions.BreadcrumbsEdit = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/shop/options",
        exact: true,
        text: i18n.t("breadcrumbs.extras_and_services"),
    },
    {
        text: i18n.t("breadcrumbs.edit"),
        disabled: true,
    },
];
DataOptions.TableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "priority",
        width: "15%",
    },
    {
        text: i18n.t("table_headers.name"),
        value: "name",
        width: "25%",
    },
    {
        text: i18n.t("table_headers.type"),
        value: "type",
        width: "15%",
    },
    {
        text: i18n.t("table_headers.tariff_transparency"),
        value: "active",
        width: "25%",
    },
    {
        text: i18n.t("table_headers.price"),
        value: "price",
        width: "25%",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];

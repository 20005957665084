import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
let CompanyDashboard = class CompanyDashboard extends Vue {
    created() {
        if (this.GetCurrentCompanyItem == undefined) {
            this.$router.push("/company/list");
        }
        else {
            this.DoSetCurrentCompanyItem(this.GetCurrentCompanyItem.uuid);
            this.$router.push("/company/edit/" + this.GetCurrentCompanyItem.uuid);
        }
    }
};
__decorate([
    Getter("getCurrentCompanyItem")
], CompanyDashboard.prototype, "GetCurrentCompanyItem", void 0);
__decorate([
    Action("doSetCurrentCompanyItem")
], CompanyDashboard.prototype, "DoSetCurrentCompanyItem", void 0);
CompanyDashboard = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], CompanyDashboard);
export default CompanyDashboard;

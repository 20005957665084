import StaticPages from "@/views/CMS/StaticPages/StaticPages.vue";
import StaticPageCreate from "@/views/CMS/StaticPages/StaticPageCreate.vue";
import EditStaticPage from "@/views/CMS/StaticPages/EditStaticPage.vue";
const routes = [
    {
        path: "/cms/static-pages/list",
        name: "StaticPagesList",
        component: StaticPages,
    },
    {
        path: "/cms/static-pages/create",
        name: "StaticPageCreate",
        component: StaticPageCreate,
    },
    {
        path: "/cms/static-pages/edit/:uuid",
        name: "EditStaticPage",
        component: EditStaticPage,
    },
];
export default routes;

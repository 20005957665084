import ApiEnter from "@/api/ApiEnter";
const state = {
    CurrentSessionUUID: undefined,
    CurrentSessionUser: undefined,
};
const getters = {
    getCurrentSessionUUID(state) {
        return state.CurrentSessionUUID;
    },
    getCurrentSessionUser(state) {
        return state.CurrentSessionUser;
    },
    haveCurrentSession(state) {
        return state.CurrentSessionUUID != undefined;
    },
};
const mutations = {
    setCurrentSessionUUID(state, payload) {
        ApiEnter.CurrentSessionUUID = payload;
        state.CurrentSessionUUID = payload;
    },
    setCurrentSessionUser(state, payload) {
        state.CurrentSessionUser = payload;
    },
};
const actions = {
    async doUpdateCurrentUser(ctx) {
        let sessionUUID = ctx.state.CurrentSessionUUID;
        if (sessionUUID == undefined) {
            sessionUUID = localStorage.getItem("XSessionUUID");
            if (sessionUUID == undefined) {
                return;
            }
            ctx.commit("setCurrentSessionUUID", sessionUUID);
        }
        try {
            const user = await ApiEnter.GetUserFromSession(sessionUUID);
            ctx.commit("setCurrentSessionUser", user);
        }
        catch (err) {
            ctx.commit("setCurrentSessionUser", undefined);
            ctx.commit("setCurrentSessionUUID", undefined);
        }
    },
    async doUpdateCurrentSessionUUID(ctx, payload) {
        if (payload == undefined) {
            const session_uuid = ctx.state.CurrentSessionUUID;
            if (session_uuid != undefined) {
                ApiEnter.Logout(session_uuid);
            }
            localStorage.removeItem("XSessionUUID");
            ctx.commit("setCurrentSessionUser", undefined);
            ctx.commit("setCurrentSessionUUID", undefined);
            return;
        }
        localStorage.setItem("XSessionUUID", payload);
        ctx.commit("setCurrentSessionUUID", payload);
        await ctx.dispatch("doUpdateCurrentUser");
        // Сбрасываем данные о Tenant - дабы запустился PreLoader который обновит всю инфу на фронте.
        ctx.commit("setCurrentTenant", undefined);
    },
    async DoUpdateAdminUserSissionUUID(ctx, payload) {
        if (payload == undefined) {
            const session_uuid = ctx.state.CurrentSessionUUID;
            if (session_uuid != undefined) {
                ApiEnter.Logout(session_uuid);
            }
            localStorage.removeItem("XSessionUUID");
            ctx.commit("setCurrentSessionUser", undefined);
            ctx.commit("setCurrentSessionUUID", undefined);
            return;
        }
        localStorage.setItem("XSessionUUID", payload);
        ctx.commit("setCurrentSessionUUID", payload);
        await ctx.dispatch("doUpdateCurrentUser");
        // Сбрасываем данные о Tenant - дабы запустился PreLoader который обновит всю инфу на фронте.
        ctx.commit("setCurrentTenant", undefined);
    },
};
export default {
    state: state,
    getters: getters,
    mutations: mutations,
    actions: actions,
};

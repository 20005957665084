import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import Editor from "@tinymce/tinymce-vue";
import ApiStores from "@/api/ApiStores";
import sweetalert from "sweetalert";
let ImportStores = class ImportStores extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home"
            },
            {
                to: "/cms/stores/list",
                exact: true,
                disabled: true,
                text: "CMS"
            },
            {
                text: "Import Stores",
                disabled: true
            }
        ];
        this.HasLoading = false;
        this.FileUpload = null;
        this.ParsingProgress = null;
        this.isLoadToDb = false;
    }
    async waitForParsing() {
        const response = await ApiStores.GetCSVFileParsingProgress(ApiEnter.CurrentSessionUUID);
        if (typeof response === "boolean") {
            return;
        }
        let current = response.current;
        let end = response.end;
        if (current === end) {
            this.ParsingProgress = response;
        }
        while (current !== end) {
            await new Promise(resolve => setTimeout(resolve, 1000));
            const temp = await ApiStores.GetCSVFileParsingProgress(ApiEnter.CurrentSessionUUID);
            if (typeof temp === "boolean") {
                this.ParsingProgress = null;
                return;
            }
            current = temp.current;
            end = temp.end;
            this.ParsingProgress = temp;
        }
    }
    async UploadFile(isLoad) {
        this.HasLoading = true;
        this.isLoadToDb = isLoad;
        if (this.FileUpload) {
            await ApiStores.UploadCSVFile(ApiEnter.CurrentSessionUUID, this.FileUpload, isLoad);
            await new Promise(resolve => setTimeout(resolve, 1000));
            await this.waitForParsing();
            if (isLoad) {
                await sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success"
                });
            }
        }
        else {
            this.ParsingProgress = null;
        }
        this.HasLoading = false;
    }
};
ImportStores = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, Editor }
    })
], ImportStores);
export default ImportStores;

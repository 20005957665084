import axios from "axios";
class ApiShopOptions {
    static async GetOptions(session_uuid) {
        try {
            const result = await axios.get("/api/shop/options/get-options", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get options");
        }
    }
    static async GetOptionByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/shop/options/get-option-by", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateOption(session_uuid, name, priority, publish, type, price, period, value, measure, connect, link_for_button, limits, overview, note, bss, seo_title, seo_description, seo_keywords, seo_url, active, tariff_overview, prospects_info, contract) {
        try {
            const result = await axios.post("/api/shop/options/create-option", {
                name: name,
                priority: priority,
                publish: publish,
                type: type,
                price: price,
                period: period,
                value: value,
                measure: measure,
                connect: connect,
                link_for_button: link_for_button,
                limits: limits,
                overview: overview,
                note: note,
                bss: bss,
                seo_title: seo_title,
                seo_description: seo_description,
                seo_keywords: seo_keywords,
                seo_url: seo_url,
                active: active,
                tariff_overview: tariff_overview,
                prospects_info: prospects_info,
                contract: contract,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateOptionPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/shop/options/change-option-publish", {
                uuid: uuid,
                publish: publish
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateOptionInfo(name, priority, publish, type, price, period, value, measure, connect, link_for_button, limits, overview, note, bss, seo_title, seo_description, seo_keywords, seo_url, active, tariff_overview, prospects_info, contract, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/shop/options/change-option-info", {
                uuid: uuid,
                name: name,
                priority: priority,
                publish: publish,
                type: type,
                price: price,
                period: period,
                value: value,
                measure: measure,
                connect: connect,
                link_for_button: link_for_button,
                limits: limits,
                overview: overview,
                note: note,
                bss: bss,
                seo_title: seo_title,
                seo_description: seo_description,
                seo_keywords: seo_keywords,
                seo_url: seo_url,
                active: active,
                tariff_overview: tariff_overview,
                prospects_info: prospects_info,
                contract: contract,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteOption(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/shop/options/delete-option", {
                uuid: uuid
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid
                }
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
}
export default ApiShopOptions;

import { __decorate } from "tslib";
import { Component, Prop, Ref, Vue } from "vue-property-decorator";
let FormGenerator = class FormGenerator extends Vue {
    get GetKeysFormModel() {
        return Object.keys(this.FormModel);
    }
    OnInputValueChanged(inputItem) {
        this.$emit("change", inputItem);
    }
};
__decorate([
    Prop({ default: undefined })
], FormGenerator.prototype, "Title", void 0);
__decorate([
    Prop()
], FormGenerator.prototype, "FormModel", void 0);
__decorate([
    Ref("current-form")
], FormGenerator.prototype, "CurrentForm", void 0);
FormGenerator = __decorate([
    Component({
        components: {},
    })
], FormGenerator);
export default FormGenerator;

import { __decorate } from "tslib";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
let ResidentialAddress = class ResidentialAddress extends Vue {
    closeModal() {
        return true;
    }
};
__decorate([
    Prop()
], ResidentialAddress.prototype, "isOpen", void 0);
__decorate([
    Emit("closeModal")
], ResidentialAddress.prototype, "closeModal", null);
ResidentialAddress = __decorate([
    Component({})
], ResidentialAddress);
export default ResidentialAddress;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { Action, Getter } from "vuex-class";
import EnterTemplate from "@/components/Template/EnterTemplate.vue";
let PreLoading = class PreLoading extends Vue {
    async created() {
        await this.DoUpdateCurrentUser();
        await this.DoUpdateCurrentCompanyList();
        await this.DoUpdateCurrentCompanyItem();
        await this.DoUpdateCurrentTenant();
        setInterval(() => {
            this.DoGetNewOrdersList();
        }, 60000);
        this.$root.$children[0].OnChangeRoute(this.$route);
    }
};
__decorate([
    Action("doUpdateCurrentTenant")
], PreLoading.prototype, "DoUpdateCurrentTenant", void 0);
__decorate([
    Action("doUpdateCurrentUser")
], PreLoading.prototype, "DoUpdateCurrentUser", void 0);
__decorate([
    Action("doGetNewOrdersList")
], PreLoading.prototype, "DoGetNewOrdersList", void 0);
__decorate([
    Action("doUpdateCurrentCompanyItem")
], PreLoading.prototype, "DoUpdateCurrentCompanyItem", void 0);
__decorate([
    Action("doUpdateCurrentCompanyList")
], PreLoading.prototype, "DoUpdateCurrentCompanyList", void 0);
__decorate([
    Getter("haveCurrentSession")
], PreLoading.prototype, "HaveCurrentSession", void 0);
__decorate([
    Getter("getCurrentCompanyItem")
], PreLoading.prototype, "GetCurrentCompanyItem", void 0);
PreLoading = __decorate([
    Component({
        components: { EnterTemplate },
    })
], PreLoading);
export default PreLoading;

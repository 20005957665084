import axios from "axios";
export default class ApiStores {
    static async GetStoresList(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-sales-points", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async GetCoordinatesAddress(session_uuid, address) {
        try {
            const result = await axios.get("/api/admin/get-geocode-address?address=" + address, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async UploadCSVFile(session_uuid, file, isLoad) {
        try {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("isLoad", String(isLoad));
            const result = await axios.post("/api/admin/upload-csv-sales-points", formData, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "Content-Type": "multipart/form-data;",
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async GetCSVFileParsingProgress(session_uuid) {
        try {
            const result = await axios.get("/api/admin/upload-csv-sales-points/progress", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                    "Content-Type": "multipart/form-data;",
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async CreateStore(session_uuid, address, longitude, latitude, name, status) {
        try {
            const result = await axios.post("/api/admin/create-sale-point", {
                status: status,
                address: address,
                longitude: String(longitude),
                latitude: String(latitude),
                store_name: name,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateStore(session_uuid, address, longitude, latitude, name, status, uuid) {
        try {
            const result = await axios.post("/api/admin/update-sale-point", {
                status: status,
                address: address,
                longitude: String(longitude),
                latitude: String(latitude),
                store_name: name,
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async GetStoreByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get("/api/admin/get-sale-point?uuid=" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async DeleteStore(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-sale-point", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeStorePublish(session_uuid, uuid, status) {
        try {
            const result = await axios.post("/api/admin/change-sale-point-publish", {
                uuid: uuid,
                status: status,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
}

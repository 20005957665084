class DataProfile {
}
DataProfile.SelectSexList = [
    {
        label: "Мужской",
        value: "m",
    },
    {
        label: "Женский",
        value: "f",
    },
];
export default DataProfile;

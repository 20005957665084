import { __decorate } from "tslib";
import { Component, Ref, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiCompany from "@/api/ApiCompany";
import ApiEnter from "@/api/ApiEnter";
import { Action } from "vuex-class";
import DataCompanyCreate from "@/data/Company/DataCompanyCreate";
import PageHeader from "@/components/UI/PageHeader.vue";
import FormGenerator from "@/components/UI/Form/FormGenerator.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
let CompanyCreate = class CompanyCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataCompanyCreate.Breadcrumbs;
        this.CompanyInfoModel = DataCompanyCreate.CompanyInfoModel;
        this.ContactInfoModel = DataCompanyCreate.ContactInfoModel;
        this.FinanceInfoModel = DataCompanyCreate.FinanceInfoModel;
    }
    created() {
        this.HasRequest = false;
    }
    mounted() {
        document.title = "Создание новой компании";
    }
    async OnClickSubmit() {
        const validate = this.CompanyFormGenerator.CurrentForm.validate() && this.ContactFormGenerator.CurrentForm.validate() && this.FinanceFormGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.HasRequest = true;
            this.$forceUpdate();
            const company_uuid = await ApiCompany.CreateCompanyFromSession(ApiEnter.CurrentSessionUUID, this.CompanyInfoModel.Name.value, this.CompanyInfoModel.Inn.value, this.CompanyInfoModel.Ogrn.value, this.CompanyInfoModel.OfficalName.value);
            if (company_uuid == undefined || company_uuid.length != 36) {
                sweetalert({
                    title: "Ошибка запроса!",
                    text: "Ошибка создания компании: " + company_uuid,
                    icon: "info",
                });
                return;
            }
            this.DoUpdateCurrentCompanyList();
            ApiCompany.UpdateCompanyLegalInfoFromSession(ApiEnter.CurrentSessionUUID, company_uuid, this.CompanyInfoModel.OfficalAddress.value, this.CompanyInfoModel.OfficalName.value, this.CompanyInfoModel.Address.value);
            ApiCompany.UpdateCompanyContactInfoFromSession(ApiEnter.CurrentSessionUUID, company_uuid, this.ContactInfoModel.CeoFullName.value, this.ContactInfoModel.CompanyEmail.value, this.ContactInfoModel.CompanyPhone.value);
            ApiCompany.UpdateCompanyBankInfoFromSession(ApiEnter.CurrentSessionUUID, company_uuid, this.FinanceInfoModel.BankRs.value, this.FinanceInfoModel.Bik.value, this.FinanceInfoModel.BankKs.value, this.FinanceInfoModel.BankName.value);
            await this.DoSetCurrentCompanyItem(company_uuid);
            this.HasRequest = false;
            this.$forceUpdate();
            sweetalert({
                title: "Успех!",
                text: `Компания успешно создана!`,
                icon: "success",
            }).then(() => {
                this.$router.push(`/company/dashboard/${company_uuid}`);
            });
        }
    }
    OnClickHelp() {
        sweetalert({
            title: "Упс...",
            text: "Раздел помощи на стадии разработки, функционал будет доступен позже.",
            icon: "info",
        });
    }
};
__decorate([
    Action("doSetCurrentCompanyItem")
], CompanyCreate.prototype, "DoSetCurrentCompanyItem", void 0);
__decorate([
    Action("doUpdateCurrentCompanyList")
], CompanyCreate.prototype, "DoUpdateCurrentCompanyList", void 0);
__decorate([
    Ref("company-form-generator")
], CompanyCreate.prototype, "CompanyFormGenerator", void 0);
__decorate([
    Ref("contact-form-generator")
], CompanyCreate.prototype, "ContactFormGenerator", void 0);
__decorate([
    Ref("finance-form-generator")
], CompanyCreate.prototype, "FinanceFormGenerator", void 0);
CompanyCreate = __decorate([
    Component({
        components: { StandartTemplate, PageHeader, FormGenerator },
    })
], CompanyCreate);
export default CompanyCreate;

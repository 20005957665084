import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import dayjs from "dayjs";
import DataOfferDetails from "@/data/OfferDetails/DataOfferDetails";
import ApiOfferDetails from "@/api/ApiOfferDetails";
import ApiOffer from "@/api/ApiOffer";
let OffersDetailsList = class OffersDetailsList extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: this.$t("menu.home"),
            },
            {
                to: "/",
                text: this.$t("menu.cms"),
            },
            {
                to: "/",
                text: "Offer details",
                disabled: true,
            },
        ];
        this.HasRequest = false;
        this.TableHeaders = DataOfferDetails.TableHeaders;
        this.TableItems = [];
    }
    async GetOfferDetails() {
        try {
            this.HasRequest = true;
            this.TableItems = await ApiOfferDetails.GetOfferDetailsList(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
        finally {
            this.HasRequest = false;
        }
    }
    created() {
        this.GetOfferDetails();
    }
    formatDate(item) {
        return dayjs.tz(item, "Europe/Rome").format("DD.MM.YYYY");
    }
    async UpdateBSSOffers() {
        this.HasRequest = true;
        this.TableItems = [];
        const response = await ApiOffer.UpdateBSSOffers(ApiEnter.CurrentSessionUUID);
        if (!response) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error") + response,
                icon: "error",
            });
        }
        await this.GetOfferDetails();
        this.HasRequest = false;
    }
};
OffersDetailsList = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], OffersDetailsList);
export default OffersDetailsList;

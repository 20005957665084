import { __decorate } from "tslib";
import { Component, Ref, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiCompany from "@/api/ApiCompany";
import ApiEnter from "@/api/ApiEnter";
import { Action, Getter, Mutation } from "vuex-class";
import PageHeader from "@/components/UI/PageHeader.vue";
import FormGenerator from "@/components/UI/Form/FormGenerator.vue";
import DataCompanyEdit from "@/data/Company/DataCompanyEdit";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
let CompanyEdit = class CompanyEdit extends Vue {
    constructor() {
        super(...arguments);
        this.CompanyInfoModel = DataCompanyEdit.CompanyInfoModel;
        this.ContactInfoModel = DataCompanyEdit.ContactInfoModel;
        this.FinanceInfoModel = DataCompanyEdit.FinanceInfoModel;
    }
    created() {
        this.Breadcrumbs = DataCompanyEdit.GetBreadcrumbs(this.GetCurrentCompanyItem);
        this.HasLoadedForm = false;
        this.HasRequest = false;
        this.CurrentCompanyUUID = this.$route.params.company_uuid;
        this.DoLoadForm();
    }
    async DoLoadForm() {
        const companyInfo = await ApiCompany.GetCompanyItemFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID);
        if (companyInfo == undefined) {
            sweetalert({
                title: "Упс!",
                text: "Компания по вашему запросу - отсуствует!",
                icon: "error",
            }).then(() => {
                this.$router.push("/");
            });
            return;
        }
        document.title = "Редактирование компании: (" + companyInfo.id + ") " + companyInfo.name;
        this.SetCurrentCompanyItem(companyInfo);
        const legalInfo = await ApiCompany.GetCompanyLegalInfoFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID);
        const contactInfo = await ApiCompany.GetCompanyContactInfoFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID);
        const bankInfo = await ApiCompany.GetCompanyBankInfoFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID);
        if (legalInfo == undefined || contactInfo == undefined || bankInfo == undefined) {
            sweetalert({
                title: "Error!",
                text: "Во время загрузки информации - возникла ошибка, не все данные были заружены!",
                icon: "error",
            }).then(() => {
                this.$router.push("/");
            });
            return;
        }
        this.SetInputValue(this.CompanyInfoModel.Name, this.GetCurrentCompanyItem?.name);
        this.SetInputValue(this.CompanyInfoModel.Address, legalInfo.address);
        this.SetInputValue(this.CompanyInfoModel.OfficalAddress, legalInfo.offical_addres);
        this.SetInputValue(this.CompanyInfoModel.OfficalName, legalInfo.offical_name);
        this.SetInputValue(this.CompanyInfoModel.Ogrn, legalInfo.offical_ogrn);
        this.SetInputValue(this.CompanyInfoModel.Inn, legalInfo.offical_inn);
        this.SetInputValue(this.ContactInfoModel.CeoFullName, contactInfo.ceo_full_name);
        this.SetInputValue(this.ContactInfoModel.CompanyEmail, contactInfo.company_email);
        this.SetInputValue(this.ContactInfoModel.CompanyPhone, contactInfo.company_phone);
        this.SetInputValue(this.FinanceInfoModel.BankRs, bankInfo.bank_rs);
        this.SetInputValue(this.FinanceInfoModel.BankKs, bankInfo.bank_ks);
        this.SetInputValue(this.FinanceInfoModel.Bik, bankInfo.bik);
        this.SetInputValue(this.FinanceInfoModel.BankName, bankInfo.bank_name);
        this.HasLoadedForm = true;
        this.$forceUpdate();
    }
    mounted() {
        if (this.GetCurrentCompanyItem == undefined) {
            return;
        }
        document.title = "Редактирование компании...";
    }
    async OnClickSubmit() {
        const validate = this.CompanyFormGenerator.CurrentForm.validate() && this.ContactFormGenerator.CurrentForm.validate() && this.FinanceFormGenerator.CurrentForm.validate();
        if (!validate) {
            sweetalert({
                title: "Ошибка в форме!",
                text: "Вы заполнили не все поля или есть ошибки!",
                icon: "error",
            });
            return;
        }
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.HasRequest = true;
            this.$forceUpdate();
            await ApiCompany.UpdateCompanyNameFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.CompanyInfoModel.Name.value);
            ApiCompany.UpdateCompanyLegalInfoFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.CompanyInfoModel.OfficalAddress.value, this.CompanyInfoModel.OfficalName.value, this.CompanyInfoModel.Address.value);
            ApiCompany.UpdateCompanyContactInfoFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.ContactInfoModel.CeoFullName.value, this.ContactInfoModel.CompanyEmail.value, this.ContactInfoModel.CompanyPhone.value);
            ApiCompany.UpdateCompanyBankInfoFromSession(ApiEnter.CurrentSessionUUID, this.CurrentCompanyUUID, this.FinanceInfoModel.BankRs.value, this.FinanceInfoModel.Bik.value, this.FinanceInfoModel.BankKs.value, this.FinanceInfoModel.BankName.value);
            this.DoUpdateCurrentCompanyItem();
            this.DoUpdateCurrentCompanyList();
            this.HasRequest = false;
            this.$forceUpdate();
            sweetalert({
                title: "Успех!",
                text: `Изменения успешно внесены!`,
                icon: "success",
            }).then(() => {
                this.$router.push(`/company/dashboard/${this.CurrentCompanyUUID}`);
            });
        }
    }
    OnClickHelp() {
        sweetalert({
            title: "Упс...",
            text: "Раздел помощи на стадии разработки, функционал будет доступен позже.",
            icon: "info",
        });
    }
    SetInputValue(model, line) {
        model.value = line;
    }
};
__decorate([
    Getter("getCurrentCompanyList")
], CompanyEdit.prototype, "GetCurrentCompanyList", void 0);
__decorate([
    Getter("getCurrentCompanyItem")
], CompanyEdit.prototype, "GetCurrentCompanyItem", void 0);
__decorate([
    Action("doSetCurrentCompanyItem")
], CompanyEdit.prototype, "DoSetCurrentCompanyItem", void 0);
__decorate([
    Action("doUpdateCurrentCompanyItem")
], CompanyEdit.prototype, "DoUpdateCurrentCompanyItem", void 0);
__decorate([
    Action("doUpdateCurrentCompanyList")
], CompanyEdit.prototype, "DoUpdateCurrentCompanyList", void 0);
__decorate([
    Mutation("setCurrentCompanyItem")
], CompanyEdit.prototype, "SetCurrentCompanyItem", void 0);
__decorate([
    Ref("company-form-generator")
], CompanyEdit.prototype, "CompanyFormGenerator", void 0);
__decorate([
    Ref("contact-form-generator")
], CompanyEdit.prototype, "ContactFormGenerator", void 0);
__decorate([
    Ref("finance-form-generator")
], CompanyEdit.prototype, "FinanceFormGenerator", void 0);
CompanyEdit = __decorate([
    Component({
        components: { StandartTemplate, PageHeader, FormGenerator },
    })
], CompanyEdit);
export default CompanyEdit;

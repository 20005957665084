import axios from "axios";
class ApiSlider {
    static async GetSlider(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-slider", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get blog");
        }
    }
    static async GetSliderDisplay(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-slider-display", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get blog");
        }
    }
    static async GetSliderByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/get-slider-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateSlider(session_uuid, title, subtitle, publish, display_text, image, alt, image_m, alt_m, link) {
        try {
            const result = await axios.post("/api/admin/create-slider", {
                title: title,
                subtitle: subtitle,
                publish: publish,
                display_text: display_text,
                image: image,
                alt: alt || "",
                image_m: image_m,
                alt_m: alt_m || "",
                link: link,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateSliderPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-slider-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateSliderInfo(title, subtitle, publish, display_text, image, alt, image_m, alt_m, link, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-slider-info", {
                uuid: uuid,
                title: title,
                subtitle: subtitle,
                publish: publish,
                display_text: display_text,
                image: image,
                alt: alt || "",
                image_m: image_m,
                alt_m: alt_m || "",
                link: link,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteSlider(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-slider", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateSliderSort(slider_sort, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-slider-sort", {
                uuid: uuid,
                slider_sort: slider_sort,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateOfferSort(slider_sort, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-offer-sort", {
                uuid: uuid,
                slider_sort: slider_sort,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
}
export default ApiSlider;

export default class DataOfferDetails {
}
DataOfferDetails.TableHeaders = [
    {
        text: "ID",
        value: "bss_details_id",
    },
    {
        text: "Offer name (BSS)",
        value: "bss_details_title",
    },
    {
        text: "Display name",
        value: "name_en",
    },
    {
        text: "Price (BSS)",
        value: "bss_details_renewal_price",
    },
    {
        text: "Edit",
        value: "action",
    },
];

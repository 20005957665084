var ECompanyEmployeeEnum;
(function (ECompanyEmployeeEnum) {
    let CompanyEmployeeStatus;
    (function (CompanyEmployeeStatus) {
        CompanyEmployeeStatus[CompanyEmployeeStatus["NoActive"] = 0] = "NoActive";
        CompanyEmployeeStatus[CompanyEmployeeStatus["Active"] = 1] = "Active";
        CompanyEmployeeStatus[CompanyEmployeeStatus["Dismissed"] = 2] = "Dismissed";
        CompanyEmployeeStatus[CompanyEmployeeStatus["Deleted"] = 3] = "Deleted";
        CompanyEmployeeStatus[CompanyEmployeeStatus["__LENGHT"] = 4] = "__LENGHT";
    })(CompanyEmployeeStatus = ECompanyEmployeeEnum.CompanyEmployeeStatus || (ECompanyEmployeeEnum.CompanyEmployeeStatus = {}));
    let CompanyEmployeeRole;
    (function (CompanyEmployeeRole) {
        CompanyEmployeeRole[CompanyEmployeeRole["Driver"] = 0] = "Driver";
        CompanyEmployeeRole[CompanyEmployeeRole["Manager"] = 1] = "Manager";
        CompanyEmployeeRole[CompanyEmployeeRole["Accountant"] = 2] = "Accountant";
        CompanyEmployeeRole[CompanyEmployeeRole["Admin"] = 3] = "Admin";
        CompanyEmployeeRole[CompanyEmployeeRole["Owner"] = 4] = "Owner";
        CompanyEmployeeRole[CompanyEmployeeRole["__LENGHT"] = 5] = "__LENGHT";
    })(CompanyEmployeeRole = ECompanyEmployeeEnum.CompanyEmployeeRole || (ECompanyEmployeeEnum.CompanyEmployeeRole = {}));
})(ECompanyEmployeeEnum || (ECompanyEmployeeEnum = {}));
export default ECompanyEmployeeEnum;

import axios from "axios";
class ApiAdminPanel {
    static async GetUsers(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-users", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async GetUser(session_uuid, user_uuid) {
        try {
            const result = await axios.post("/api/admin/get-user", {
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
    static async UpdateUserData(first_name, last_name, phone, session_uuid, user_uuid) {
        try {
            const result = await axios.post("/api/admin/change-user-info", {
                first_name: first_name,
                last_name: last_name,
                phone: phone,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async UpdateUserStatus(user_uuid, session_uuid, status) {
        try {
            const result = await axios.post("/api/admin/change-status", {
                status: status,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update status");
        }
    }
    static async UpdateUserRole(user_uuid, session_uuid, role) {
        try {
            const result = await axios.post("/api/admin/change-role", {
                system_role: role,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error update role");
        }
    }
    static async DeleteUser(session_uuid, user_uuid) {
        try {
            const result = await axios.post("/api/admin/delete-user", {
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async UpdateEmail(email, user_uuid, session_uuid) {
        try {
            const result = await axios.post("/api/admin/update-email", {
                email: email,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async UpdatePassword(password, user_uuid, session_uuid) {
        try {
            const result = await axios.post("/api/admin/update-password", {
                password: password,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get users");
        }
    }
    static async GetCompanies(session_uuid) {
        try {
            const result = await axios.post("/api/admin/get-companies", {
                limit: "100",
                offset: "0",
                search: "",
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get companies");
        }
    }
    static async GetCompanyByUUID(session_uuid, company_uuid) {
        try {
            const result = await axios.post("/api/admin/get-company", {
                company_uuid: company_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateCompanyInfo(session_uuid, company_uuid, offical_name, inn, ogrn, offical_address, address) {
        try {
            const result = await axios.post("/api/admin/change-company-legal-info", {
                company_uuid: company_uuid,
                offical_name: offical_name,
                offical_address: offical_address,
                address: address,
                inn: inn,
                ogrn: ogrn,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateContactInfo(session_uuid, company_uuid, ceo_full_name, company_email, company_phone) {
        try {
            const result = await axios.post("/api/admin/change-company-contact-info", {
                company_uuid: company_uuid,
                ceo_full_name: ceo_full_name,
                company_email: company_email,
                company_phone: company_phone,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFinanceInfo(session_uuid, company_uuid, bank_rs, bik, bank_ks, bank_name) {
        try {
            const result = await axios.post("/api/admin/change-company-bank-info", {
                company_uuid: company_uuid,
                bank_rs: bank_rs,
                bik: bik,
                bank_ks: bank_ks,
                bank_name: bank_name,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async DeleteCompany(session_uuid, company_uuid) {
        try {
            const result = await axios.post("/api/admin/delete-company", {
                company_uuid: company_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateCompanyStatus(session_uuid, company_uuid, status) {
        try {
            const result = await axios.post("/api/admin/change-company-status", {
                company_uuid: company_uuid,
                status: status,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateCompanyOwner(session_uuid, company_uuid, user_uuid) {
        try {
            const result = await axios.post("/api/admin/update-owner-company", {
                company_uuid: company_uuid,
                user_uuid: user_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async LoginOnUser(session_uuid, email) {
        try {
            const result = await axios.post("/api/admin/login-on-user-session", {
                email: email,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.session_uuid;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UploadFile(session_uuid, file, currentDir) {
        const formData = new FormData();
        formData.append("file", file);
        try {
            const result = await axios.post("/api/file/upload", formData, {
                headers: {
                    "x-tenant": "null",
                    "Content-Type": "multipart/form-data",
                    "x-session-token": session_uuid,
                },
                params: {
                    currentDir: currentDir,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetFiles(session_uuid, file_name) {
        try {
            const result = await axios.post("/api/file/get-files", {
                file_name: file_name,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
                responseType: "blob",
            });
            return result.data;
        }
        catch (e) {
            throw new Error("Error get file");
        }
    }
}
export default ApiAdminPanel;

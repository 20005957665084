import SimpleUploadAdapter from "@ckeditor/ckeditor5-upload/src/adapters/simpleuploadadapter";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import Bold from "@ckeditor/ckeditor5-basic-styles/src/bold";
import Italic from "@ckeditor/ckeditor5-basic-styles/src/italic";
import Strikethrough from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import Alignment from "@ckeditor/ckeditor5-alignment/src/alignment";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";
import FontBackgroundColor from "@ckeditor/ckeditor5-font/src/fontbackgroundcolor";
import FontColor from "@ckeditor/ckeditor5-font/src/fontcolor";
import FontFamily from "@ckeditor/ckeditor5-font/src/fontfamily";
import FontSize from "@ckeditor/ckeditor5-font/src/fontsize";
import BlockQuote from "@ckeditor/ckeditor5-block-quote/src/blockquote";
import Underline from "@ckeditor/ckeditor5-basic-styles/src/underline";
import Image from "@ckeditor/ckeditor5-image/src/image";
import ImageInsert from "@ckeditor/ckeditor5-image/src/imageinsert";
import ImageStyle from "@ckeditor/ckeditor5-image/src/imagestyle";
import ImageBlock from "@ckeditor/ckeditor5-image/src/imageblock";
import ImageToolbar from "@ckeditor/ckeditor5-image/src/imagetoolbar";
import ImageUpload from "@ckeditor/ckeditor5-image/src/imageupload";
import List from "@ckeditor/ckeditor5-list/src/list";
import TodoList from "@ckeditor/ckeditor5-list/src/todolist";
import Indent from "@ckeditor/ckeditor5-indent/src/indent";
import IndentBlock from "@ckeditor/ckeditor5-indent/src/indentblock";
import Table from "@ckeditor/ckeditor5-table/src/table";
import TableCaption from "@ckeditor/ckeditor5-table/src/tablecaption";
import TableCellProperties from "@ckeditor/ckeditor5-table/src/tablecellproperties";
import TableColumnResize from "@ckeditor/ckeditor5-table/src/tablecolumnresize";
import TableProperties from "@ckeditor/ckeditor5-table/src/tableproperties";
import TableToolbar from "@ckeditor/ckeditor5-table/src/tabletoolbar";
import Code from "@ckeditor/ckeditor5-basic-styles/src/code";
import CodeBlock from "@ckeditor/ckeditor5-code-block/src/codeblock";
import HorizontalLine from "@ckeditor/ckeditor5-horizontal-line/src/horizontalline";
import PageBreak from "@ckeditor/ckeditor5-page-break/src/pagebreak";
import RemoveFormat from "@ckeditor/ckeditor5-remove-format/src/removeformat";
import SpecialCharacters from "@ckeditor/ckeditor5-special-characters/src/specialcharacters";
import SpecialCharactersArrows from "@ckeditor/ckeditor5-special-characters/src/specialcharactersarrows";
import SpecialCharactersCurrency from "@ckeditor/ckeditor5-special-characters/src/specialcharacterscurrency";
import SpecialCharactersEssentials from "@ckeditor/ckeditor5-special-characters/src/specialcharactersessentials";
import SpecialCharactersLatin from "@ckeditor/ckeditor5-special-characters/src/specialcharacterslatin";
import SpecialCharactersMathematical from "@ckeditor/ckeditor5-special-characters/src/specialcharactersmathematical";
import SpecialCharactersText from "@ckeditor/ckeditor5-special-characters/src/specialcharacterstext";
import MediaEmbed from "@ckeditor/ckeditor5-media-embed/src/mediaembed";
import MediaEmbedToolbar from "@ckeditor/ckeditor5-media-embed/src/mediaembedtoolbar";
import FindAndReplace from "@ckeditor/ckeditor5-find-and-replace/src/findandreplace";
import SourceEditing from "@ckeditor/ckeditor5-source-editing/src/sourceediting";
import Subscript from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import Superscript from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import Link from "@ckeditor/ckeditor5-link/src/link";
import AutoLink from "@ckeditor/ckeditor5-link/src/autolink";
import ImageResize from "@ckeditor/ckeditor5-image/src/imageresize";
import ImageCaption from "@ckeditor/ckeditor5-image/src/imagecaption";
import CKBox from "@ckeditor/ckeditor5-ckbox/src/ckbox";
import { CloudServices } from "@ckeditor/ckeditor5-cloud-services";
import PictureEditing from "@ckeditor/ckeditor5-image/src/pictureediting";
class CKEditor {
    static editorConfig(token) {
        return {
            plugins: [
                EssentialsPlugin,
                CloudServices,
                Bold,
                PictureEditing,
                CKBox,
                Italic,
                ImageCaption,
                Alignment,
                Heading,
                SimpleUploadAdapter,
                Highlight,
                FontBackgroundColor,
                FontColor,
                FontFamily,
                FontSize,
                BlockQuote,
                Underline,
                ImageBlock,
                Image,
                ImageInsert,
                ImageStyle,
                ImageToolbar,
                ImageUpload,
                List,
                TodoList,
                Indent,
                IndentBlock,
                Table,
                TableCaption,
                TableCellProperties,
                TableColumnResize,
                TableProperties,
                TableToolbar,
                Code,
                CodeBlock,
                HorizontalLine,
                PageBreak,
                RemoveFormat,
                SpecialCharacters,
                SpecialCharactersArrows,
                SpecialCharactersCurrency,
                SpecialCharactersEssentials,
                SpecialCharactersLatin,
                SpecialCharactersMathematical,
                SpecialCharactersText,
                MediaEmbed,
                MediaEmbedToolbar,
                FindAndReplace,
                SourceEditing,
                Subscript,
                Superscript,
                Link,
                AutoLink,
            ],
            toolbar: [
                "Heading",
                "bold",
                "italic",
                "ckbox",
                "underline",
                "alignment",
                "link",
                "|",
                "undo",
                "redo",
                "|",
                "blockQuote",
                "|",
                "horizontalLine",
                "outdent",
                "indent",
                "|",
                "fontSize",
                "fontColor",
                "fontBackgroundColor",
                "Highlight",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "mediaEmbed",
                "insertTable",
                "|",
                "code",
                "codeBlock",
                "sourceEditing",
                "|",
                "pageBreak",
                "findAndReplace",
                "|",
                "removeFormat",
                "specialCharacters",
                "subscript",
                "superscript",
                "insertImage",
                "imageStyle:inline",
                "imageStyle:wrapText",
                "imageStyle:breakText",
                "toggleImageCaption",
                "imageTextAlternative",
                "resizeImage",
                "imageBlock",
                "imageStyle:block",
                "imageStyle:side",
                "toggleImageCaption",
                "imageTextAlternative",
                "linkImage",
            ],
            table: {
                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"],
            },
            ckbox: {
                // The development token endpoint is a special endpoint to help you in getting started with
                // CKEditor Cloud Services.
                // Please note the development token endpoint returns tokens with the CKBox administrator role.
                // It offers unrestricted, full access to the service and will expire 30 days after being used for the first time.
                // -------------------------------------------------------------
                // !!! You should not use it on production !!!
                // -------------------------------------------------------------
                // Read more: https://ckeditor.com/docs/ckbox/latest/guides/configuration/authentication.html#token-endpoint
                // You need to provide your own token endpoint here
                tokenUrl: "https://91408.cke-cs.com/token/dev/6d42c312fb00cfeeefb129100ea3a39533bcf898f65a1be58b7510323c43?limit=10",
            },
            simpleUpload: {
                uploadUrl: "/api/file/ck-upload",
                // Enable the XMLHttpRequest.withCredentials property.
                withCredentials: true,
                // Headers sent along with the XMLHttpRequest to the upload server.
                headers: {
                    "x-session-token": token,
                    "x-tenant": null,
                },
            },
            extraPlugins: [ImageResize, Image],
            image: {
                toolbar: ["imageTextAlternative", "|", "imageStyle:full", "imageStyle:side"],
            },
        };
    }
    static editorStaticPagesConfig(token) {
        return {
            plugins: [
                EssentialsPlugin,
                CloudServices,
                Bold,
                PictureEditing,
                CKBox,
                Italic,
                ImageCaption,
                Alignment,
                Heading,
                SimpleUploadAdapter,
                Highlight,
                FontBackgroundColor,
                FontColor,
                FontFamily,
                FontSize,
                BlockQuote,
                Underline,
                ImageBlock,
                Image,
                ImageInsert,
                ImageStyle,
                ImageToolbar,
                ImageUpload,
                List,
                TodoList,
                Indent,
                IndentBlock,
                Table,
                TableCaption,
                TableCellProperties,
                TableColumnResize,
                TableProperties,
                TableToolbar,
                Code,
                CodeBlock,
                HorizontalLine,
                PageBreak,
                RemoveFormat,
                SpecialCharacters,
                SpecialCharactersArrows,
                SpecialCharactersCurrency,
                SpecialCharactersEssentials,
                SpecialCharactersLatin,
                SpecialCharactersMathematical,
                SpecialCharactersText,
                MediaEmbed,
                MediaEmbedToolbar,
                FindAndReplace,
                SourceEditing,
                Subscript,
                Superscript,
                Link,
                AutoLink,
            ],
            toolbar: [
                "Heading",
                "bold",
                "italic",
                "ckbox",
                "underline",
                "alignment",
                "link",
                "|",
                "undo",
                "redo",
                "|",
                "blockQuote",
                "|",
                "horizontalLine",
                "outdent",
                "indent",
                "|",
                "fontColor",
                "|",
                "bulletedList",
                "numberedList",
                "|",
                "mediaEmbed",
                "insertTable",
                "|",
                "code",
                "codeBlock",
                "sourceEditing",
                "|",
                "pageBreak",
                "findAndReplace",
                "|",
                "removeFormat",
                "specialCharacters",
                "subscript",
                "superscript",
                "insertImage",
                "imageStyle:inline",
                "imageStyle:wrapText",
                "imageStyle:breakText",
                "toggleImageCaption",
                "imageTextAlternative",
                "resizeImage",
                "imageBlock",
                "imageStyle:block",
                "imageStyle:side",
                "toggleImageCaption",
                "imageTextAlternative",
                "linkImage",
            ],
            table: {
                contentToolbar: ["tableColumn", "tableRow", "mergeTableCells", "tableCellProperties", "tableProperties"],
            },
            ckbox: {
                // The development token endpoint is a special endpoint to help you in getting started with
                // CKEditor Cloud Services.
                // Please note the development token endpoint returns tokens with the CKBox administrator role.
                // It offers unrestricted, full access to the service and will expire 30 days after being used for the first time.
                // -------------------------------------------------------------
                // !!! You should not use it on production !!!
                // -------------------------------------------------------------
                // Read more: https://ckeditor.com/docs/ckbox/latest/guides/configuration/authentication.html#token-endpoint
                // You need to provide your own token endpoint here
                tokenUrl: "https://91408.cke-cs.com/token/dev/6d42c312fb00cfeeefb129100ea3a39533bcf898f65a1be58b7510323c43?limit=10",
            },
            heading: {
                options: [
                    { model: "heading2", view: "h2", title: "Heading 2", class: "ck-heading_heading2" },
                    { model: "heading3", view: "h3", title: "Heading 3", class: "ck-heading_heading3" },
                    { model: "heading4", view: "h4", title: "Heading 4", class: "ck-heading_heading4" },
                ],
            },
            fontColor: {
                colors: [
                    {
                        color: "#E85217",
                        label: "Orange",
                    },
                    {
                        color: "#002759",
                        label: "Midnight Blue",
                    },
                    {
                        color: "#2F06D2",
                        label: "Electric Blue",
                    },
                    {
                        color: "#292929",
                        label: "Black",
                    },
                    {
                        color: "#ECF4FF",
                        label: "Grayish Blue",
                    },
                    {
                        color: "#EBEBEB",
                        label: "Grayish",
                    },
                    {
                        color: "#E0E0E0",
                        label: "Grayish2",
                    },
                    {
                        color: "#A5A5A5",
                        label: "Grayish3",
                    },
                    {
                        color: "#ffffff",
                        label: "White",
                    },
                ],
            },
            simpleUpload: {
                uploadUrl: "/api/file/ck-upload",
                // Enable the XMLHttpRequest.withCredentials property.
                withCredentials: true,
                // Headers sent along with the XMLHttpRequest to the upload server.
                headers: {
                    "x-session-token": token,
                    "x-tenant": null,
                },
            },
            extraPlugins: [ImageResize, Image],
            image: {
                toolbar: ["imageTextAlternative", "|", "imageStyle:full", "imageStyle:side"],
            },
        };
    }
    static editorOffersShort() {
        return {
            plugins: [EssentialsPlugin, CloudServices, Heading, Bold, Italic, Underline, Strikethrough, Code, Subscript, Superscript, RemoveFormat],
            toolbar: {
                items: ["bold", "italic", "underline", "strikethrough", "removeFormat"],
            },
        };
    }
}
export default CKEditor;

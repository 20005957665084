import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import EnterTemplate from "@/components/Template/EnterTemplate.vue";
import InputTextFromEnter from "@/components/Template/Enter/InputTextFromEnter.vue";
import InputEnterEmail from "@/components/Enter/InputEnterEmail.vue";
import InputEnterPassword from "@/components/Enter/InputEnterPassword.vue";
import sweetalert from "sweetalert";
let ForgotPassword = class ForgotPassword extends Vue {
    constructor() {
        super(...arguments);
        this.FormValues = {
            Email: "",
            Password: "",
        };
    }
    mounted() {
        document.title = "Восстановление пароля";
        sweetalert({
            title: "Упс!",
            text: "Данная функция, пока что недоступна! Обратитесь в поддержку!",
            icon: "warning",
        }).then(() => {
            this.$router.push("/enter/login/");
        });
    }
};
ForgotPassword = __decorate([
    Component({
        components: { InputEnterPassword, InputEnterEmail, InputTextFromEnter, EnterTemplate },
    })
], ForgotPassword);
export default ForgotPassword;

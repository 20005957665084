import axios from "axios";
class ApiProfile {
    static async profileUpdate(first_name, last_name, second_name, phone, birthday, sex, session_uuid) {
        try {
            const result = await axios.post("/api/account/profile/profile-update", {
                first_name: first_name,
                last_name: last_name,
                second_name: second_name,
                phone: phone,
                birthday: birthday,
                sex: sex,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
            return undefined;
        }
    }
    static async emailUpdate(new_email, session_uuid) {
        try {
            const result = await axios.post("/api/account/profile/email-update", {
                new_email: new_email,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
            return false;
        }
    }
    static async passwordUpdate(old_password, new_password, session_uuid) {
        try {
            const result = await axios.post("/api/account/profile/password-update", {
                old_password: old_password,
                new_password: new_password,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
            return false;
        }
    }
    static async deleteProfile(user_uuid, user_role, session_uuid) {
        try {
            const result = await axios.post("/api/account/profile/delete-self", {
                user_uuid: user_uuid,
                user_role: user_role,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
            return false;
        }
    }
    static async GetUserFromEmail(session_uuid, email) {
        try {
            const result = await axios.get(`/api/account/get-from-email?email=${email}`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.user;
        }
        catch (e) {
            console.error(e);
            return undefined;
        }
    }
}
export default ApiProfile;

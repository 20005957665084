import i18n from "@/i18n/index.ts";
export default class DataStores {
}
DataStores.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/faq",
        text: "Stores List",
        disabled: true,
    },
];
DataStores.TableHeaders = [
    {
        text: "Store name",
        value: "store_name",
    },
    {
        text: "Address",
        value: "address",
    },
    {
        text: "Create At",
        value: "created_at",
    },
    {
        text: "Status",
        value: "status",
    },
    {
        text: "Actions",
        value: "actions",
    },
];

import axios from "axios";
export default class ApiReports {
    static async getReportsList(session_uuid, date_from, date_to, report_type) {
        try {
            const result = await axios.post("/api/admin/reports/get-data", {
                date_from: date_from,
                date_to: date_to,
                type: report_type
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}

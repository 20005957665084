import i18n from "@/i18n/index.ts";
export default class DataFaq {
}
DataFaq.FaqQuestionsBreadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/faq",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/faq",
        text: i18n.t("breadcrumbs.support_faq"),
    },
    {
        to: "/faq",
        text: i18n.t("breadcrumbs.questions"),
        disabled: true,
    },
];
DataFaq.FaqCategoriesBreadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/faq",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/faq",
        exact: true,
        text: i18n.t("breadcrumbs.support_faq"),
    },
    {
        to: "/admin/faq/edit",
        text: i18n.t("breadcrumbs.categories"),
        disabled: true,
    },
];
DataFaq.QuestionsTableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "priority",
        width: "10%",
    },
    {
        text: i18n.t("table_headers.question"),
        value: "question",
        width: "40%",
    },
    {
        text: i18n.t("table_headers.category"),
        value: "category",
        width: "30%",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
DataFaq.CategoriesTableHeaders = [
    {
        text: i18n.t("table_headers.sorting"),
        value: "priority",
        width: "15%",
    },
    {
        text: i18n.t("table_headers.category_name"),
        value: "name",
        width: "70%",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];

export default class DataUser {
}
DataUser.Breadcrumbs = [
    {
        to: "/",
        text: "Home",
    },
    {
        to: "/admin/users",
        text: "Users managment",
        disabled: true,
    },
];
DataUser.TableHeaders = [
    {
        text: "First name",
        value: "first_name",
    },
    {
        text: "Last name",
        value: "last_name",
    },
    {
        text: "Role",
        value: "system_role",
    },
    {
        text: "Status",
        value: "status",
    },
    {
        text: "Email",
        value: "email",
    },
    {
        text: "Action",
        value: "action",
    },
];

import UtilFormValidation from "@/util/Form/UtilFormValidation";
import i18n from "@/i18n/index.ts";
class DataBlogCreate {
}
DataBlogCreate.BlogInfoModel = {
    Title: {
        name: i18n.t("table_headers.title"),
        message: "Title of article",
        value: "",
        default: "",
        type: "text",
        maxlength: 128,
    },
    Image: {
        name: i18n.t("table_headers.image"),
        message: "Image of article",
        value: "",
        default: "",
        type: "text",
        maxlength: 256,
        rules: [...UtilFormValidation.CreateOnMinValueLengthRules(2), ...UtilFormValidation.CreateOnMaxValueLengthRules(256)],
    },
    IsShow: {
        name: i18n.t("table_headers.is_show"),
        message: "",
        value: "",
        default: "",
        type: "select",
        maxlength: 20,
        select: [
            {
                label: "True",
                value: "true",
            },
            {
                label: "False",
                value: "false",
            },
        ],
        rules: [],
    },
};
DataBlogCreate.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/admin/catalog/categories",
        text: i18n.t("breadcrumbs.blog"),
        disabled: true,
    },
];
DataBlogCreate.TableHeaders = [
    {
        text: i18n.t("table_headers.date"),
        value: "create_at",
        width: "15%",
    },
    {
        text: i18n.t("table_headers.name"),
        value: "title",
        width: "70%",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];
export default DataBlogCreate;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiSlider from "@/api/ApiSlider";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import Editor from "@tinymce/tinymce-vue";
import dayjs from "dayjs";
import ApiAdmin from "@/api/ApiAdmin";
import UtilGeneral from "@/util/Common/UtilGeneral";
let SliderEdit = class SliderEdit extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/faq/question",
                text: "CMS",
            },
            {
                text: "Slider",
                disabled: true,
            },
        ];
        this.newPost = {
            title: "",
            subtitle: "",
            publish: true,
            display_text: true,
            link: "",
            image: null,
            alt: "",
            image_m: null,
            alt_m: "",
            image_name: "",
            image_m_name: "",
            created: "",
        };
        this.HasLoading = false;
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    mounted() {
        this.CurrentSliderUUID = this.$route.params.slider_uuid;
        this.DoLoadForm();
    }
    async DoLoadForm() {
        const sliderInfo = await ApiSlider.GetSliderByUUID(ApiEnter.CurrentSessionUUID, this.CurrentSliderUUID);
        if (sliderInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.newPost.title = sliderInfo.name;
        this.newPost.subtitle = sliderInfo.subtitle;
        this.newPost.publish = sliderInfo.publish;
        this.newPost.display_text = sliderInfo.display_text;
        this.newPost.image_name = sliderInfo.image;
        this.newPost.alt = sliderInfo.alt;
        this.newPost.image_m_name = sliderInfo.image_m;
        this.newPost.alt_m = sliderInfo.alt_m;
        await this.GetFiles(sliderInfo.image, sliderInfo.image_m);
        this.newPost.link = sliderInfo.link;
        this.newPost.created = dayjs.tz(sliderInfo.create_at, "Europe/Rome").format("DD.MM.YYYY HH:mm");
        console.log(this.newPost.image_name);
        console.log(this.newPost.image.name);
        this.$forceUpdate();
    }
    async GetFiles(file_name1, file_name2) {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, file_name1).then(response => {
            this.newPost.image = new File([new Blob([response.data])], file_name1.split("/")[8]);
        });
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, file_name2).then(response => {
            this.newPost.image_m = new File([new Blob([response.data])], file_name2.split("/")[8]);
        });
    }
    async DownloadFile1() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newPost.image_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newPost.image_name.split("/")[8];
            a.click();
        });
    }
    async DownloadFile2() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.newPost.image_m_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.newPost.image_m_name.split("/")[8];
            a.click();
        });
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        try {
            const response = await ApiSlider.UpdateSliderInfo(this.newPost.title, this.newPost.subtitle, this.newPost.publish ? 1 : 0, this.newPost.display_text ? 1 : 0, this.newPost.image ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newPost.image, this.newPost.image_name)) : "", this.newPost.alt, this.newPost.image_m ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newPost.image_m, this.newPost.image_m_name)) : "", this.newPost.alt_m, this.newPost.link, ApiEnter.CurrentSessionUUID, this.CurrentSliderUUID);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.$router.back();
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
        this.HasLoading = false;
    }
};
SliderEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, Editor },
    })
], SliderEdit);
export default SliderEdit;

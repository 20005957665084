import axios from "axios";
import { EOrderEnum } from "@/struct/order/EOrderEnum";
export default class ApiOrders {
    static async GetOrdersList(session_uuid, startDate, endDate) {
        try {
            const result = await axios.get(`/api/web-api/orders/get-list?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get orders");
        }
    }
    static async MakeRefund(session_uuid, order_uuid, amount) {
        try {
            const result = await axios.post("/api/web-api/ricarica/refund", {
                order_uuid: order_uuid,
                amount: amount,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async RecreateOrderFromBSS(session_uuid, order_uuid) {
        try {
            const result = await axios.post("/api/web-api/order/again-create-order-bss", {
                order_uuid: order_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get orders");
        }
    }
    static async GetOrderByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get("/api/web-api/order/get?uuid=" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async RefreshOrderStatuses(session_uuid) {
        try {
            const result = await axios.get("/api/web-api/order/refresh-statuses", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateNote(session_uuid, uuid, note) {
        try {
            const result = await axios.post("/api/web-api/order/edit-note", {
                uuid: uuid,
                note: note,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateContactInfo(session_uuid, uuid, phone, email) {
        try {
            const result = await axios.post("/api/web-api/order/update-contact-info", {
                uuid: uuid,
                phone: phone,
                email: email,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateDeliveryAddress(session_uuid, uuid, street_name, street_number, city, province, zip_code, country) {
        try {
            const result = await axios.post("/api/web-api/order/update-delivery-address", {
                uuid: uuid,
                street_name: street_name,
                street_number: street_number,
                city: city,
                province: province,
                zip_code: zip_code,
                country: country,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateDeliveryTracking(session_uuid, uuid, tracking, tracking_link) {
        try {
            const result = await axios.post("/api/web-api/order/update-tracking", {
                uuid: uuid,
                tracking: tracking,
                tracking_link: tracking_link,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetOrderHistory(session_uuid, order_uuid) {
        try {
            const result = await axios.get("/api/web-api/order/get-history?order_uuid=" + order_uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetOrderRefundHistory(session_uuid, order_uuid) {
        try {
            const result = await axios.get("/api/web-api/order/get-refund-history?order_uuid=" + order_uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetDonorsOperation() {
        try {
            const result = await axios.get("/api/public/get-donor-operators");
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeSIMStatus(session_uuid, product_uuid, status) {
        try {
            const result = await axios.post("/api/web-api/order/change-sim-status", {
                product_uuid: product_uuid,
                status: status,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async CancelOrderWithProducts(session_uuid, order_uuid) {
        try {
            const result = await axios.post("/api/web-api/order/cancel", {
                order_uuid: order_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangePaymentStatus(session_uuid, order_uuid, status) {
        try {
            const result = await axios.post("/api/web-api/payment/status/to-refunded", {
                order_uuid: order_uuid,
                full_refunded: status === EOrderEnum.OrderPaymentStatus.Refunded,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeOrderStatusToProgress(session_uuid, order_uuid) {
        try {
            const result = await axios.post("/api/web-api/order/status/to-progress", {
                order_uuid: order_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async ChangeOrderStatusToDelivery(session_uuid, order_uuid) {
        try {
            const result = await axios.post("/api/web-api/order/status/to-delivery", {
                order_uuid: order_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async GetNewOrdersLength(session_uuid) {
        try {
            const result = await axios.get("/api/web-api/order/get-new-orders-length", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}

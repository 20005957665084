import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataOrder from "@/data/Shop/Order/DataOrder";
import DeliveryTracking from "@/components/Modals/Order/DeliveryTracking.vue";
import Note from "@/components/Modals/Order/Note.vue";
import ContactInformation from "@/components/Modals/Order/ContactInformation.vue";
import DeliveryAdress from "@/components/Modals/Order/DeliveryAdress.vue";
import ResidentialAddress from "@/components/Modals/Order/ResidentialAddress.vue";
import sweetalert from "sweetalert";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
import ApiEnter from "@/api/ApiEnter";
import ApiOrders from "@/api/ApiOrders";
import dayjs from "dayjs";
import { EOrderEnum } from "@/struct/order/EOrderEnum";
import Swal from "sweetalert2";
let OrderView = class OrderView extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataOrder.Breadcrumbs;
        this.isOpenDeliveryTracking = false;
        this.isOpenNoteEdit = false;
        this.isOpenContactInformation = false;
        this.isOpenDeliveryAddress = false;
        this.isOpenResidentialAddress = false;
        this.HasLoadingCancelOrder = false;
        this.ChangePaymentStatusList = [];
        this.ChangeOrderStatusList = [];
        this.OrderHistoryList = [];
        this.OrderRefundList = [];
        this.DonorsOperationsList = [];
        this.PaymentStatus = EOrderEnum.OrderPaymentStatus;
        this.hasActiveRecreate = false;
        this.DataOrder = null;
        this.isHaveErrorFromBSS = false;
        this.panels1 = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13];
        this.panels2 = [0, 1, 2, 3, 4, 5, 6];
        this.panelsHistory = false;
        this.EnumOrderSIM = EOrderEnum.OrderProductSimStatus;
        this.headersTableSims = [
            { text: "Item name", value: "name", width: "45%" },
            { text: "Item type", value: "type", width: "45%" },
            { text: "Price", value: "price", width: "10%" },
        ];
        this.headersTableOrderHistory = [
            { text: "Changed parameter", value: "type" },
            { text: "Old value", value: "from" },
            { text: "New value", value: "to" },
            { text: "Changed by", value: "email" },
            { text: "Change time", value: "create_at" },
        ];
        this.headersTableOrderRefundHistory = [
            { text: "Status", value: "refund_status" },
            { text: "Amount", value: "amount" },
            { text: "Changed by", value: "email" },
            { text: "Change time", value: "create_at" },
        ];
    }
    CanAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role != EUserEnum.SystemRole.NoRole;
    }
    CancelSim(product_uuid) {
        sweetalert({
            title: this.$t("orders.cancel_sim"),
            text: this.$t("orders.confirm_canceled"),
            buttons: [this.$t("orders.cancel"), this.$t("orders.ok")],
        }).then(async (isSuccess) => {
            if (isSuccess) {
                const response = await ApiOrders.ChangeSIMStatus(ApiEnter.CurrentSessionUUID, product_uuid, EOrderEnum.OrderProductSimStatus.CancelledByCustomer);
                if (response) {
                    sweetalert({
                        title: this.$t("orders.cancel_sim"),
                        text: this.$t("orders.sim_cancelled"),
                        icon: "success",
                    });
                    this.LoadOrderData();
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    ValidateAmount(amount) {
        return Number(amount).toFixed(2).replace(".", ",") + "€";
    }
    ChangeStatusOrder(value) {
        sweetalert({
            title: this.$t("orders.change_status"),
            text: this.$t("orders.confirm_status_change"),
            buttons: [this.$t("orders.cancel"), this.$t("orders.confirm")],
        }).then(async (isSuccess) => {
            if (isSuccess) {
                if (value === EOrderEnum.OrderStatus.InProcess) {
                    const response = await ApiOrders.ChangeOrderStatusToProgress(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID);
                    if (response) {
                        sweetalert({
                            title: this.$t("general.success"),
                            text: this.$t("general.success_updated"),
                            icon: "success",
                        });
                    }
                    else {
                        sweetalert({
                            title: this.$t("general.error"),
                            text: this.$t("general.request_error"),
                            icon: "info",
                        });
                    }
                }
                if (value === EOrderEnum.OrderStatus.InDelivery) {
                    const response = await ApiOrders.ChangeOrderStatusToDelivery(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID);
                    if (response) {
                        sweetalert({
                            title: this.$t("general.success"),
                            text: this.$t("general.success_updated"),
                            icon: "success",
                        });
                    }
                    else {
                        sweetalert({
                            title: this.$t("general.error"),
                            text: this.$t("general.request_error"),
                            icon: "info",
                        });
                    }
                }
                this.LoadOrderData();
                this.LoadHistory();
            }
        });
    }
    async ChangeStatusPayment(value) {
        sweetalert({
            title: this.$t("orders.change_status"),
            text: this.$t("orders.confirm_status_change"),
            buttons: [this.$t("orders.cancel"), this.$t("orders.confirm")],
        }).then(async (isSuccess) => {
            if (isSuccess) {
                const response = await ApiOrders.ChangePaymentStatus(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID, value);
                if (response) {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_updated"),
                        icon: "success",
                    });
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    formatDate(item) {
        const convertDate = new Date(item);
        return dayjs.tz(convertDate, "Europe/Rome").format("DD-MM-YYYY (HH:mm:s)");
    }
    ValidateOrderHistoryType(type) {
        const Enums = EOrderEnum.OrderHistoryType;
        if (type === Enums.PaymentStatus) {
            return this.$t("orders.payment_status");
        }
        if (type === Enums.ProductStatus) {
            return this.$t("orders.product_status");
        }
        if (type === Enums.OrderCreate) {
            return this.$t("orders.order_create");
        }
        if (type === Enums.ChangeValue) {
            return this.$t("orders.change_value");
        }
        if (type === Enums.OrderStatus) {
            return this.$t("orders.order_status");
        }
    }
    async CancelOrder() {
        sweetalert({
            title: "Cancel ORDER",
            text: "Are you sure you want to cancel the entire Order (all products in the order)?\n",
            buttons: [this.$t("orders.cancel"), this.$t("orders.ok")],
        }).then(async (isSuccess) => {
            if (isSuccess) {
                this.HasLoadingCancelOrder = true;
                const response = await ApiOrders.CancelOrderWithProducts(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID);
                if (response) {
                    sweetalert({
                        title: "Cancel ORDER",
                        text: "Order success cancelled",
                        icon: "success",
                    });
                    this.LoadOrderData();
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
                this.HasLoadingCancelOrder = false;
            }
        });
    }
    ValidateCanCancelOrder() {
        if (this.DataOrder?.order_info.status == EOrderEnum.OrderStatus.NotFinished)
            return false;
        if (this.DataOrder?.order_info.status == EOrderEnum.OrderStatus.Cancelled)
            return false;
        if (this.DataOrder?.order_info.status == EOrderEnum.OrderStatus.Finished)
            return false;
        return true;
    }
    ValidatePaymentMethod() {
        if (this.DataOrder?.order_payment.paid_method == "apmgw_expresscheckout") {
            try {
                return "PayPal (" + this.DataOrder?.order_payment.paid_data.externalAccountDescription.split("email:")[1] + ")";
            }
            catch (e) {
                console.error("error to get email Paypal");
                return "PayPal";
            }
        }
        if (this.DataOrder?.order_payment.paid_data) {
            return this.DataOrder.order_payment.paid_data.cardCompany + " " + this.DataOrder.order_payment.paid_data.cardNumber;
        }
    }
    async LoadOrderData() {
        this.ChangePaymentStatusList = [];
        this.ChangeOrderStatusList = [];
        const response = await ApiOrders.GetOrderByUUID(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID);
        if (typeof response !== "boolean") {
            if (response?.order_payment?.paid_data) {
                response.order_payment.paid_data = JSON.parse(response.order_payment.paid_data);
            }
            response.product_data.forEach((item) => {
                item.table_items = [];
                if (item.error_bss || item.is_send_bss == 0) {
                    this.isHaveErrorFromBSS = true;
                }
                if (item.external_data) {
                    item.external_data = JSON.parse(item.external_data);
                    item.table_items.push({
                        name: item.offer_name,
                        type: "Offer",
                        price: this.ValidateAmount(item.external_data.RenewalPrice),
                    });
                    if (item.portation_donor_network && item.portation_donor_network.length > 0) {
                        if (item.portation_donor_network == "29") {
                            item.table_items.push({
                                name: "Activation SIM",
                                type: "Activation",
                                price: this.ValidateAmount(item.external_data.ActivationPriceVodafone),
                            });
                        }
                        else {
                            item.table_items.push({
                                name: "Activation SIM",
                                type: "Activation",
                                price: this.ValidateAmount(item.external_data.ActivationPriceOtherNetworks),
                            });
                        }
                    }
                    else {
                        item.table_items.push({
                            name: "Activation SIM",
                            type: "Activation",
                            price: this.ValidateAmount(item.external_data.ActivationPriceNewActivations),
                        });
                    }
                    item.options.forEach((option) => {
                        if (item.table_items) {
                            item.table_items.push({
                                name: option.optional_name,
                                type: "Option",
                                price: this.ValidateAmount(option.optional_price),
                            });
                        }
                    });
                    if (item.topup_amount) {
                        item.table_items.push({
                            name: "Topup",
                            type: "Balance",
                            price: this.ValidateAmount(item.topup_amount),
                        });
                    }
                }
                else {
                    item.table_items.push({
                        name: "Replacement SIM",
                        type: "SIM card",
                        price: this.ValidateAmount(item.offer_price),
                    });
                }
            });
            if (response.order_info.status === EOrderEnum.OrderStatus.New) {
                this.ChangeOrderStatusList = [
                    {
                        text: this.$t("orders.in_process"),
                        value: EOrderEnum.OrderStatus.InProcess,
                    },
                ];
            }
            if (response.order_info.status === EOrderEnum.OrderStatus.InProcess) {
                this.ChangeOrderStatusList = [
                    {
                        text: this.$t("orders.in_delivery"),
                        value: EOrderEnum.OrderStatus.InDelivery,
                    },
                ];
            }
            if (response.order_payment.payment_status === EOrderEnum.OrderPaymentStatus.Paid) {
                const IsHaveSIMCancelled = response.product_data.filter((item) => {
                    return item.sim_status === EOrderEnum.OrderProductSimStatus.CancelledFailedKYC || item.sim_status === EOrderEnum.OrderProductSimStatus.CancelledByCustomer;
                });
                if (IsHaveSIMCancelled.length) {
                    this.ChangePaymentStatusList = [
                        {
                            text: this.$t("orders.partially_refunded"),
                            value: EOrderEnum.OrderPaymentStatus.PartiallyRefunded,
                        },
                        {
                            text: this.$t("orders.refunded"),
                            value: EOrderEnum.OrderPaymentStatus.Refunded,
                        },
                    ];
                }
            }
            this.DataOrder = response;
        }
    }
    ValidateCanCancelSim(value) {
        if (this.DataOrder?.product_data.length <= 1)
            return false;
        if (value === EOrderEnum.OrderProductSimStatus.NoActive)
            return false;
        if (value === EOrderEnum.OrderProductSimStatus.CancelledByCustomer)
            return false;
        if (value === EOrderEnum.OrderProductSimStatus.CancelledFailedKYC)
            return false;
        if (value === EOrderEnum.OrderProductSimStatus.AcceptedFromCompletedKYC)
            return false;
        return true;
    }
    ValidateOrderStatus(value, type) {
        const Enums = EOrderEnum.OrderStatus;
        if (type == "color") {
            if (value === Enums.NotFinished)
                return "grey darken-1 text-white";
            if (value === Enums.New)
                return "yellow darken-2 text-black";
            if (value === Enums.InProcess)
                return "orange";
            if (value === Enums.InDelivery)
                return "light-blue";
            if (value === Enums.Delivered)
                return "blue";
            if (value === Enums.Cancelled)
                return "red";
            if (value === Enums.Finished)
                return "green";
            if (value === Enums.PartiallyFinished)
                return "teal darken-2 text-black";
        }
        if (type == "text") {
            if (value === Enums.NotFinished)
                return this.$t("orders.not_finished");
            if (value === Enums.New)
                return this.$t("orders.new");
            if (value === Enums.InProcess)
                return this.$t("orders.in_process");
            if (value === Enums.InDelivery)
                return this.$t("orders.in_delivery");
            if (value === Enums.Delivered)
                return this.$t("orders.delivered");
            if (value === Enums.Cancelled)
                return this.$t("orders.cancelled");
            if (value === Enums.Finished)
                return this.$t("orders.finished");
            if (value === Enums.PartiallyFinished)
                return this.$t("orders.partially_finished");
        }
    }
    ValidatePaymentStatus(value, type) {
        const Enums = EOrderEnum.OrderPaymentStatus;
        if (type == "color") {
            if (value === Enums.NotPaid)
                return "grey darken-1 text-white";
            if (value === Enums.Refunded)
                return "red";
            if (value === Enums.PartiallyRefunded)
                return "pink";
            if (value === Enums.Paid)
                return "green";
        }
        if (type == "text") {
            if (value === Enums.NotPaid)
                return this.$t("orders.not_paid");
            if (value === Enums.Refunded)
                return this.$t("orders.refunded");
            if (value === Enums.PartiallyRefunded)
                return this.$t("orders.partially_refunded");
            if (value === Enums.Paid)
                return this.$t("orders.paid");
        }
    }
    ValidateSIMStatus(value, type) {
        const Enums = EOrderEnum.OrderProductSimStatus;
        if (type == "text") {
            if (value === Enums.PendingStartActivation)
                return this.$t("orders.pending");
            if (value === Enums.PendingWaitingKYC)
                return this.$t("orders.pending");
            if (value === Enums.PendingPaymentForNumber)
                return this.$t("orders.pending");
            if (value === Enums.PendingRepeatKYC)
                return this.$t("orders.pending");
            if (value === Enums.PendingFromStartedKYC)
                return this.$t("orders.pending");
            if (value === Enums.PendingFromCompletedKYC)
                return this.$t("orders.pending");
            if (value === Enums.AcceptedFromCompletedKYC)
                return this.$t("orders.activated");
            if (value === Enums.CancelledFailedKYC)
                return this.$t("orders.cancelled");
            if (value === Enums.CancelledByCustomer)
                return this.$t("orders.cancelled");
            if (value === Enums.New)
                return this.$t("orders.new");
            if (value === Enums.InProcess)
                return this.$t("orders.in_process");
            if (value === Enums.InDelivery)
                return "In Delivery";
        }
        if (type == "color") {
            if (value === Enums.PendingStartActivation)
                return "orange";
            if (value === Enums.New)
                return "orange";
            if (value === Enums.InProcess)
                return "orange";
            if (value === Enums.InDelivery)
                return "orange";
            if (value === Enums.PendingWaitingKYC)
                return "orange";
            if (value === Enums.PendingPaymentForNumber)
                return "orange";
            if (value === Enums.PendingRepeatKYC)
                return "orange";
            if (value === Enums.PendingFromStartedKYC)
                return "orange";
            if (value === Enums.PendingFromCompletedKYC)
                return "orange";
            if (value === Enums.AcceptedFromCompletedKYC)
                return "green";
            if (value === Enums.CancelledFailedKYC)
                return "red";
            if (value === Enums.CancelledByCustomer)
                return "red";
        }
    }
    async RecreateOrder() {
        this.hasActiveRecreate = true;
        const response = await ApiOrders.RecreateOrderFromBSS(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID);
        if (response) {
            window.location.reload();
        }
        this.hasActiveRecreate = false;
    }
    async LoadHistory() {
        const response = await ApiOrders.GetOrderHistory(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID);
        if (typeof response != "boolean") {
            this.OrderHistoryList = response;
        }
    }
    async LoadRefundHistory() {
        const response = await ApiOrders.GetOrderRefundHistory(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID);
        if (typeof response != "boolean") {
            this.OrderRefundList = response;
        }
    }
    created() {
        this.CurrentOrderUUID = this.$route.params.order_uuid;
        this.LoadDonorsOperators();
        this.LoadOrderData();
        this.LoadHistory();
        this.LoadRefundHistory();
    }
    async LoadDonorsOperators() {
        const response = await ApiOrders.GetDonorsOperation();
        if (typeof response != "boolean") {
            this.DonorsOperationsList = response;
        }
    }
    ValidateDonorsOperation(id) {
        if (this.DonorsOperationsList.length) {
            const item = this.DonorsOperationsList.find(item => item.ID === Number(id));
            if (item) {
                return item.DisplayedName;
            }
            return this.$t("orders.donor_not_found");
        }
        return this.$t("orders.donors_empty");
    }
    async MakeRefund() {
        const sumAmount = Number(this.DataOrder?.order_payment.payment_sum) - Number(this.DataOrder?.order_payment.refund_sum);
        Swal.fire({
            title: "The amount must be no more than",
            input: "text",
            inputAttributes: {
                autocapitalize: "off",
                inputmode: "decimal",
            },
            showCancelButton: true,
            confirmButtonText: "Confirm",
            showLoaderOnConfirm: true,
            preConfirm: value => {
                if (isNaN(value)) {
                    Swal.showValidationMessage(`Please write number`);
                }
                else if (parseFloat(value) > Number(sumAmount)) {
                    Swal.showValidationMessage(`Sum need be less than ${sumAmount}`);
                }
            },
            allowOutsideClick: () => !Swal.isLoading(),
        }).then(async (result) => {
            if (result.isConfirmed) {
                // Число введено корректно, можно обработать дальше
                const response = await ApiOrders.MakeRefund(ApiEnter.CurrentSessionUUID, this.CurrentOrderUUID, String(result.value));
                if (response) {
                    Swal.fire({
                        title: "Refund make successful",
                    }).then(() => {
                        window.location.reload();
                    });
                }
                else {
                    Swal.fire({
                        title: "Error with refund",
                    });
                }
            }
        });
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], OrderView.prototype, "GetCurrentSessionUser", void 0);
OrderView = __decorate([
    Component({
        components: { ResidentialAddress, DeliveryAdress, ContactInformation, Note, DeliveryTracking, PageHeader, StandartTemplate, LeftMenuTab },
    })
], OrderView);
export default OrderView;

import axios from "axios";
class ApiFeedback {
    static async GetFeedbackMessages(session_uuid) {
        try {
            const result = await axios.get("/api/feedback/contact/get-messages", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetFeedbackMessageByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get("/api/feedback/contact/get-message?uuid=" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetBecomeAPartnerMessages(session_uuid) {
        try {
            const result = await axios.get("/api/feedback/become-a-partner/get-messages", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetBecomeAPartnerMessageByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get("/api/feedback/become-a-partner/get-message?uuid=" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
}
export default ApiFeedback;

import i18n from "@/i18n/index.ts";
export default class DataVariables {
}
DataVariables.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/",
        text: i18n.t("breadcrumbs.cms"),
    },
    {
        to: "/cms/variables/list",
        text: i18n.t("breadcrumbs.variables"),
        disabled: true,
    },
];
DataVariables.TableHeaders = [
    {
        text: i18n.t("breadcrumbs.variable_name"),
        value: "name",
    },
    {
        text: i18n.t("breadcrumbs.value"),
        value: "value",
    },
    {
        text: i18n.t("breadcrumbs.created_at"),
        value: "create_at",
    },
    {
        text: i18n.t("breadcrumbs.update_at"),
        value: "update_at",
    },
    {
        text: i18n.t("breadcrumbs.action"),
        value: "action",
    },
];

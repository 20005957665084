import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import ECompanyEnum from "@/struct/company/ECompanyEnum";
import PageHeader from "@/components/UI/PageHeader.vue";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataCompanyList from "@/data/Company/DataCompanyList";
let CompanyList = class CompanyList extends Vue {
    constructor() {
        super(...arguments);
        this.TableHeaders = DataCompanyList.TableHeaders;
    }
    created() {
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Главная",
            },
            {
                disabled: true,
                to: "/company/list",
                text: "Компании",
            },
        ];
    }
    mounted() {
        document.title = "Список компаний";
    }
    GetTextBadgeFromCompanyStatus(company) {
        switch (company.status) {
            case ECompanyEnum.CompanyStatus.Active:
                return "Активна";
            case ECompanyEnum.CompanyStatus.NoActive:
                return "НеАктивна";
            case ECompanyEnum.CompanyStatus.Banned:
                return "Заблокирована";
            case ECompanyEnum.CompanyStatus.Deleted:
                return "Удалена";
        }
        return "Не Известно";
    }
    GetClassBadgeFromCompanyStatus(company) {
        switch (company.status) {
            case ECompanyEnum.CompanyStatus.Active:
                return "success";
            case ECompanyEnum.CompanyStatus.NoActive:
                return "warning";
            case ECompanyEnum.CompanyStatus.Banned:
                return "danger";
            case ECompanyEnum.CompanyStatus.Deleted:
                return "info";
        }
        return "info";
    }
    GetStatusName(status) {
        return ECompanyEnum.CompanyStatus[status];
    }
};
__decorate([
    Getter("getCurrentCompanyList")
], CompanyList.prototype, "GetCurrentCompanyList", void 0);
CompanyList = __decorate([
    Component({
        components: { StandartTemplate, PageHeader },
    })
], CompanyList);
export default CompanyList;

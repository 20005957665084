class UtilSex {
    static CanValidSex(value, allowDefaultValue = false) {
        if (value == "f" || value == "m") {
            return true;
        }
        if (allowDefaultValue == true && value == "n") {
            return true;
        }
        return false;
    }
}
export default UtilSex;

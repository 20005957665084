import axios from "axios";
class ApiEnter {
    static async Login(email, password) {
        const result = await axios.post("/api/account/enter/login", {
            email: email,
            password: password,
        }, {
            headers: {
                "x-tenant": "null",
            },
        });
        return result.data.session_uuid;
    }
    static async Registration(email, password) {
        const result = await axios.post("/api/account/enter/registration", {
            email: email,
            password: password,
            first_name: "",
            last_name: "",
            second_name: "",
            sex: "n",
        }, {
            headers: {
                "x-tenant": "null",
            },
        });
        return result.data.response.user_uuid;
    }
    static async GetUserFromSession(session_uuid) {
        const result = await axios.get("/api/account/enter/current-session", {
            headers: {
                "x-tenant": "null",
                "x-session-token": session_uuid,
            },
        });
        return result.data.response;
    }
    static async Logout(session_uuid) {
        const result = await axios.post("/api/account/enter/logout", {}, {
            headers: {
                "x-tenant": "null",
                "x-session-token": session_uuid,
            },
        });
        return result.data.response;
    }
}
ApiEnter.CurrentSessionUUID = undefined;
export default ApiEnter;

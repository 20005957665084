import { __decorate } from "tslib";
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import ApiEnter from "@/api/ApiEnter";
import ApiOrders from "@/api/ApiOrders";
import sweetalert from "sweetalert";
let Note = class Note extends Vue {
    constructor() {
        super(...arguments);
        this.NoteModel = "";
    }
    closeModal() {
        return true;
    }
    save() {
        return true;
    }
    async SaveData() {
        const response = await ApiOrders.UpdateNote(ApiEnter.CurrentSessionUUID, this.$route.params.order_uuid, this.NoteModel);
        if (response) {
            this.save();
        }
        else {
            sweetalert({
                title: "Error",
                text: "Error to change data",
                icon: "error",
            });
            this.closeModal();
        }
    }
    setData() {
        this.NoteModel = this.note;
    }
};
__decorate([
    Prop()
], Note.prototype, "isOpen", void 0);
__decorate([
    Prop()
], Note.prototype, "note", void 0);
__decorate([
    Emit("closeModal")
], Note.prototype, "closeModal", null);
__decorate([
    Emit("save")
], Note.prototype, "save", null);
__decorate([
    Watch("isOpen")
], Note.prototype, "setData", null);
Note = __decorate([
    Component({})
], Note);
export default Note;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiBlog from "@/api/ApiBlog";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import dayjs from "dayjs";
import ApiAdmin from "@/api/ApiAdmin";
import CKEditorConfig from "@/data/CKeditor/config";
import UtilGeneral from "@/util/Common/UtilGeneral";
let BlogEdit = class BlogEdit extends Vue {
    constructor() {
        super(...arguments);
        this.editor = ClassicEditor;
        this.editorConfig = CKEditorConfig.editorConfig(ApiEnter.CurrentSessionUUID);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/admin/blog",
                exact: true,
                text: "CMS",
            },
            {
                to: "/admin/blog",
                exact: true,
                text: "Blog",
            },
            {
                text: "Edit",
                disabled: true,
            },
        ];
        this.CardDesignData = ["Blue", "Orange", "White", "With image"];
        this.HasLoading = false;
        this.isOpenDate = false;
        this.PostPublish = true;
        this.PostName = "";
        this.PostDate = "";
        this.PostImage1 = null;
        this.PostImageName1 = "";
        this.PostImageAlt1 = "";
        this.PostImage2 = null;
        this.PostImageName2 = "";
        this.PostImageAlt2 = "";
        this.BlogCardDesign = "";
        this.BlogContent = "";
        this.BlogSeoDescription = "";
        this.BlogSeoKeywords = "";
        this.BlogSeoUrl = "";
        this.PostCreated = "";
    }
    async DoLoadForm() {
        const blogInfo = await ApiBlog.GetBlogByUUID(ApiEnter.CurrentSessionUUID, this.CurrentBlogUUID);
        if (blogInfo == undefined) {
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "error",
            }).then(() => {
                this.$router.go(-1);
            });
            return;
        }
        this.PostName = blogInfo.title;
        this.PostDate = blogInfo.date;
        await this.GetFiles(blogInfo.image1, blogInfo.image2);
        this.PostImageName1 = blogInfo.image1;
        this.PostImageAlt1 = blogInfo.alt1;
        this.PostImageName2 = blogInfo.image2;
        this.PostImageAlt2 = blogInfo.alt2;
        this.BlogCardDesign = blogInfo.card_design;
        this.PostPublish = blogInfo.publish;
        this.BlogContent = blogInfo.content;
        this.BlogSeoDescription = blogInfo.seo_description;
        this.BlogSeoKeywords = blogInfo.seo_keywords;
        this.BlogSeoUrl = blogInfo.seo_url;
        this.PostCreated = dayjs.tz(blogInfo.create_at, "Europe/Rome").format("DD.MM.YYYY HH:mm");
        this.$forceUpdate();
    }
    async GetFiles(file_name1, file_name2) {
        if (file_name1 !== "") {
            await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, file_name1).then(response => {
                this.PostImage1 = new File([new Blob([response.data])], file_name1.split("/")[8]);
            });
        }
        if (file_name2 !== "") {
            await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, file_name2).then(response => {
                this.PostImage2 = new File([new Blob([response.data])], file_name2.split("/")[8]);
            });
        }
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    async DownloadFile1() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.PostImageName1).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.PostImageName1.split("/")[8];
            a.click();
        });
    }
    async DownloadFile2() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.PostImageName2).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.PostImageName2.split("/")[8];
            a.click();
        });
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        const file_name1 = this.PostImage1 ? await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.PostImage1, this.PostImageName1) : "";
        const file_name2 = this.PostImage2 ? (this.PostImage2.name === this.PostImageName2.split("/")[8] ? this.PostImageName2 : await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.PostImage2)) : "";
        try {
            const response = await ApiBlog.UpdateBlogInfo(this.PostName, dayjs.tz(this.PostDate, "Europe/Rome").format("YYYY-MM-DD"), this.PostPublish ? 1 : 0, file_name1, file_name2, this.BlogCardDesign, this.BlogContent, this.BlogSeoDescription, this.BlogSeoKeywords, this.BlogSeoUrl, ApiEnter.CurrentSessionUUID, this.CurrentBlogUUID, this.PostImageAlt1, this.PostImageAlt2);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.$router.go(-1);
                });
            }
            else {
                sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error") + response,
                    icon: "error",
                });
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "error",
            });
        }
        this.HasLoading = false;
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    ValidateSeoUrl() {
        this.BlogSeoUrl = this.Translit(this.BlogSeoUrl.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
    }
    get computedDateFormatted() {
        return dayjs.tz(this.PostDate, "Europe/Rome").format("DD.MM.YYYY");
    }
    mounted() {
        this.CurrentBlogUUID = this.$route.params.blog_uuid;
        this.DoLoadForm();
    }
};
BlogEdit = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, CKEditor },
    })
], BlogEdit);
export default BlogEdit;

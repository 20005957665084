import StoresList from "@/views/CMS/Stores/StoresList.vue";
import CreateStore from "@/views/CMS/Stores/CreateStore.vue";
import EditStore from "@/views/CMS/Stores/EditStore.vue";
import ImportStores from "@/views/CMS/Stores/ImportStores.vue";
const routes = [
    {
        path: "/cms/stores/list",
        name: "StoresList",
        component: StoresList,
    },
    {
        path: "/cms/stores/create",
        name: "CreateStore",
        component: CreateStore,
    },
    {
        path: "/cms/stores/edit/:store_uuid",
        name: "EditStore",
        component: EditStore,
    },
    {
        path: "/cms/stores/import",
        name: "ImportStores",
        component: ImportStores,
    },
];
export default routes;

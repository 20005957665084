import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let InputTextFromEnter = class InputTextFromEnter extends Vue {
    created() {
        //
    }
};
__decorate([
    Prop()
], InputTextFromEnter.prototype, "name", void 0);
InputTextFromEnter = __decorate([
    Component({
        components: {},
    })
], InputTextFromEnter);
export default InputTextFromEnter;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import DataReportsPage from "@/data/Reports/DataReportsPage";
import PageHeader from "@/components/UI/PageHeader.vue";
import { EReport } from "@/struct/reports/EReport";
import ApiReports from "@/api/ApiReports";
import ApiEnter from "@/api/ApiEnter";
import dayjs from "dayjs";
import ApiAdmin from "@/api/ApiAdmin";
import { EOrderEnum } from "@/struct/order/EOrderEnum";
let ReportsPage = class ReportsPage extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataReportsPage.Breadcrumbs;
        this.selectReportType = null;
        this.selectReportTypeItems = [
            {
                text: "Report for logistic department",
                value: EReport.ReportForLogicDepartment
            },
            {
                text: "Report for APN settings",
                value: EReport.ReportForAPNSettings
            },
            {
                text: "Report for order data",
                value: EReport.ReportForOrderData
            }
        ];
        this.settingsPanelState = true;
        this.menuDateFrom = false;
        this.dateFrom = "";
        this.menuDateTo = false;
        this.dateTo = "";
        this.tableHeaders = [];
        this.hasRequest = false;
        this.xlsLink = "";
        this.csvLink = "";
        this.logicDepartamentHeaders = [
            {
                text: "Date",
                value: "create_at"
            },
            {
                text: "Order number",
                value: "id"
            },
            {
                text: "First name",
                value: "id"
            },
            {
                text: "Last name",
                value: "last_name"
            },
            {
                text: "Email",
                value: "email"
            },
            {
                text: "Phone number",
                value: "phone"
            },
            {
                text: "Delivery address - Street name",
                value: "delivery_street_name"
            },
            {
                text: "Delivery address - Street number",
                value: "delivery_street_number"
            },
            {
                text: "Delivery address - City",
                value: "delivery_city"
            },
            {
                text: "Delivery address - Province",
                value: "delivery_province"
            },
            {
                text: "Delivery address - ZIP code",
                value: "delivery_zip_code"
            },
            {
                text: "Residential address - Street name",
                value: "residential_street_name"
            },
            {
                text: "Residential address - Street number",
                value: "residential_street_number"
            },
            {
                text: "Residential address - City",
                value: "residential_city"
            },
            {
                text: "Residential address - Province",
                value: "residential_province"
            },
            {
                text: "Residential address - Province",
                value: "residential_zip_code"
            },
            {
                text: "SIM count",
                value: "sim_count"
            },
            {
                text: "Note",
                value: "note"
            }
        ];
        this.apnSettingsHeaders = [
            {
                text: "Date",
                value: "create_at"
            },
            {
                text: "Phone number",
                value: "msisdn"
            },
            {
                text: "O.S.",
                value: "platform"
            },
            {
                text: "Result",
                value: "result"
            }
        ];
        this.orderDataHeaders = [
            {
                text: "Date",
                value: "create_at"
            },
            {
                text: "Order",
                value: "id"
            },
            {
                text: "Customer",
                value: "first_name"
            },
            {
                text: "E-mail",
                value: "email"
            },
            {
                text: "Fiscal_code",
                value: "fiscal_code"
            },
            {
                text: "Phone Number",
                value: "phone"
            },
            {
                text: "Total",
                value: "amount"
            },
            {
                text: "Order Status",
                value: "status"
            },
            {
                text: "Payment status",
                value: "payment_status"
            }
        ];
        this.tableItems = [];
    }
    formatDate(item) {
        const convertDate = dayjs(item).format();
        return dayjs.tz(convertDate, "Europe/Rome").format("DD-MM-YYYY (HH:mm:s)");
    }
    openLink(path) {
        ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, path).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.target = "_blank";
            a.download = path;
            a.click();
        });
    }
    async getReports() {
        this.tableItems = [];
        this.xlsLink = "";
        this.csvLink = "";
        this.hasRequest = true;
        const date_from = this.dateFrom + " 00:00:01";
        const date_to = this.dateTo + " 23:59:59";
        switch (this.selectReportType) {
            case EReport.ReportForLogicDepartment:
                this.tableHeaders = this.logicDepartamentHeaders;
                break;
            case EReport.ReportForAPNSettings:
                this.tableHeaders = this.apnSettingsHeaders;
                break;
            case EReport.ReportForOrderData:
                this.tableHeaders = this.orderDataHeaders;
                break;
            default:
                throw new Error("Unknown selectReportType");
        }
        const response = await ApiReports.getReportsList(ApiEnter.CurrentSessionUUID, date_from, date_to, this.selectReportType);
        if (typeof response != "boolean") {
            this.tableItems = response.reports;
            this.xlsLink = response.xlsLink;
            this.csvLink = response.csvLink;
        }
        this.hasRequest = false;
    }
    ValidateAmount(amount) {
        return amount.toFixed(2).replace(".", ",") + "€";
    }
    ValidatePaymentStatus(value, type) {
        const Enums = EOrderEnum.OrderPaymentStatus;
        if (type == "color") {
            if (value === Enums.NotPaid)
                return "grey darken-1 text-white";
            if (value === Enums.Refunded)
                return "red";
            if (value === Enums.PartiallyRefunded)
                return "pink";
            if (value === Enums.Paid)
                return "green";
        }
        if (type == "text") {
            if (value === Enums.NotPaid)
                return this.$t("orders.not_paid");
            if (value === Enums.Refunded)
                return this.$t("orders.refunded");
            if (value === Enums.PartiallyRefunded)
                return this.$t("orders.partially_refunded");
            if (value === Enums.Paid)
                return this.$t("orders.paid");
        }
    }
    ValidateOrderStatus(value, type) {
        const Enums = EOrderEnum.OrderStatus;
        if (type == "color") {
            if (value === Enums.NotFinished)
                return "grey darken-1 text-white";
            if (value === Enums.New)
                return "yellow darken-2 text-black";
            if (value === Enums.InProcess)
                return "orange";
            if (value === Enums.InDelivery)
                return "light-blue";
            if (value === Enums.Delivered)
                return "blue";
            if (value === Enums.Cancelled)
                return "red";
            if (value === Enums.Finished)
                return "green";
            if (value === Enums.PartiallyFinished)
                return "teal darken-2 text-black";
        }
        if (type == "text") {
            if (value === Enums.NotFinished)
                return this.$t("orders.not_finished");
            if (value === Enums.New)
                return this.$t("orders.new");
            if (value === Enums.InProcess)
                return this.$t("orders.in_process");
            if (value === Enums.InDelivery)
                return this.$t("orders.in_delivery");
            if (value === Enums.Delivered)
                return this.$t("orders.delivered");
            if (value === Enums.Cancelled)
                return this.$t("orders.cancelled");
            if (value === Enums.Finished)
                return this.$t("orders.finished");
            if (value === Enums.PartiallyFinished)
                return this.$t("orders.partially_finished");
        }
    }
};
ReportsPage = __decorate([
    Component({
        components: { StandartTemplate, PageHeader }
    })
], ReportsPage);
export default ReportsPage;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import DataSlider from "@/data/Slider/DataSlider";
import ApiSlider from "@/api/ApiSlider";
import dayjs from "dayjs";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let SliderList = class SliderList extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataSlider.SliderListBreadcrumbs;
        this.TableHeaders = DataSlider.SliderListTableHeaders;
        this.TableItems = [];
    }
    DeleteSlider(slider_uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("slider.accept_delete"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiSlider.DeleteSlider(ApiEnter.CurrentSessionUUID, slider_uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    await this.GetSlider();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    async GetSlider() {
        try {
            this.TableItems = await ApiSlider.GetSlider(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    async ChangeSliderPublish(item) {
        try {
            const response = await ApiSlider.UpdateSliderPublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.GetSlider();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    created() {
        this.GetSlider();
    }
    formatDate(item) {
        return dayjs.tz(item, "Europe/Rome").format("DD.MM.YYYY");
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], SliderList.prototype, "GetCurrentSessionUser", void 0);
SliderList = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], SliderList);
export default SliderList;

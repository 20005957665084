import i18n from "@/i18n/index.ts";
class OtherSettings {
}
OtherSettings.Breadcrumbs = [
    {
        to: "/",
        text: i18n.t("breadcrumbs.home"),
    },
    {
        to: "/shop/offers",
        exact: true,
        text: i18n.t("breadcrumbs.shop"),
        disabled: true,
    },
    {
        to: "/shop/offers",
        text: i18n.t("breadcrumbs.other_settings"),
        disabled: true,
    },
];
export default OtherSettings;

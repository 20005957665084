import axios from "axios";
class ApiSupportFiles {
    static async GetFiles(session_uuid) {
        try {
            const result = await axios.get("/api/cms/files/get-files", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get files");
        }
    }
    static async GetFileByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/get-file-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFile(session_uuid, name, description, category, file, priority, publish) {
        try {
            const result = await axios.post("/api/cms/files/create-file", {
                name: name,
                description: description,
                category: category,
                file: file,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteFile(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/delete-file", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFilePublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/change-file-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFileInfo(name, description, category, file, priority, publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/change-file-info", {
                uuid: uuid,
                name: name,
                description: description,
                category: category,
                file: file,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async GetFilesCategories(session_uuid) {
        try {
            const result = await axios.get("/api/cms/files/get-files-categories", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get faq");
        }
    }
    static async GetFilesCategoryByUUID(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/get-files-category-by", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateFilesCategory(session_uuid, name, priority, publish) {
        try {
            const result = await axios.post("/api/cms/files/create-files-category", {
                name: name,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async DeleteFilesCategory(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/delete-files-category", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async UpdateFilesCategoryPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/change-files-category-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateFilesCategoryInfo(name, priority, publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/cms/files/change-files-category-info", {
                uuid: uuid,
                name: name,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
}
export default ApiSupportFiles;

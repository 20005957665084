import { __decorate } from "tslib";
import { Component, Ref, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import dayjs from "dayjs";
import ApiVariables from "@/api/ApiVariables";
import DataVariables from "@/data/CMS/DataVariables";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
let Variables = class Variables extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = DataVariables.Breadcrumbs;
        this.TableHeaders = DataVariables.TableHeaders;
        this.TableItems = [];
        this.isOpenDialog = false;
        this.isOpenEditDialog = false;
        this.newVariable = {
            publish: true,
            name: "",
            value: "string",
        };
        this.currentVariable = {
            publish: true,
            name: "",
            value: "",
            uuid: "",
            create_at: "",
            update_at: "",
        };
        this.HasLoading = false;
    }
    OpenDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        this.newVariable.publish = true;
        this.newVariable.name = "";
        this.newVariable.value = "";
    }
    OpenEditDialog() {
        this.isOpenEditDialog = !this.isOpenEditDialog;
    }
    async CreateVariable() {
        this.HasLoading = true;
        const validate = this.NewFormValidate.validate();
        if (!validate) {
            return void 0;
        }
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const response = await ApiVariables.CreateVariable(ApiEnter.CurrentSessionUUID, this.newVariable.name, this.newVariable.value, this.newVariable.publish ? 1 : 0);
            this.OpenDialog();
            if (!response) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            this.$forceUpdate();
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            });
            await this.GetVariables();
        }
        this.HasLoading = false;
    }
    async ChangeVariablePublish(item) {
        try {
            const response = await ApiVariables.ChangeVariablePublish(ApiEnter.CurrentSessionUUID, item.publish ? 1 : 0, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
                this.GetVariables();
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async ChangeVariable() {
        this.HasLoading = true;
        try {
            const response = await ApiVariables.ChangeVariable(ApiEnter.CurrentSessionUUID, this.currentVariable.name, this.currentVariable.value, this.currentVariable.publish ? 1 : 0, this.currentVariable.uuid);
            if (response) {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                });
                this.OpenEditDialog();
                this.GetVariables();
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
        this.HasLoading = false;
    }
    async openEditDialog(data) {
        this.currentVariable.publish = data.publish;
        this.currentVariable.name = data.name;
        this.currentVariable.value = data.value;
        this.currentVariable.update_at = dayjs.tz(data.update_at, "Europe/Rome").format("DD.MM.YYYY HH:mm:s");
        this.currentVariable.create_at = dayjs.tz(data.create_at, "Europe/Rome").format("DD.MM.YYYY HH:mm:s");
        this.currentVariable.uuid = data.uuid;
        this.OpenEditDialog();
    }
    DeleteFile(uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("variables.accept_delete"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiVariables.DeleteVariable(ApiEnter.CurrentSessionUUID, uuid);
                if (response) {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    this.GetVariables();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    async GetVariables() {
        try {
            this.TableItems = await ApiVariables.GetVariables(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    watchNewName() {
        this.newVariable.name = this.newVariable.name.replace(/[^0-9a-z_\\-]/g, "");
    }
    watchCurrentName() {
        this.currentVariable.name = this.currentVariable.name.replace(/[^0-9a-z_\\-]/g, "");
    }
    formatDate(item) {
        return dayjs.tz(item, "Europe/Rome").format("DD-MM-YYYY (HH:mm:s)");
    }
    created() {
        this.GetVariables();
    }
};
__decorate([
    Ref("new-form")
], Variables.prototype, "NewFormValidate", void 0);
__decorate([
    Getter("getCurrentSessionUser")
], Variables.prototype, "GetCurrentSessionUser", void 0);
Variables = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], Variables);
export default Variables;

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import CKEditorConfig from "@/data/CKeditor/config";
import ApiStaticPages from "@/api/ApiStaticPages";
let StaticPageCreate = class StaticPageCreate extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: "Home",
            },
            {
                to: "/admin/blog",
                text: "CMS",
                disabled: true,
            },
            {
                text: "Create static page",
                disabled: true,
            },
        ];
        this.HasLoading = false;
        this.editor = ClassicEditor;
        this.editorConfig = CKEditorConfig.editorStaticPagesConfig(ApiEnter.CurrentSessionUUID);
        this.newData = {
            publish: true,
            pageTitle: "",
            content: "",
            seoTitle: "",
            description: "",
            keywords: "",
            url: "",
            dontIndexPage: false,
            dontShowSitemap: false,
        };
    }
    Translit(str) {
        let ru = "А-а-Б-б-В-в-Ґ-ґ-Г-г-Д-д-Е-е-Ё-ё-Є-є-Ж-ж-З-з-И-и-І-і-Ї-ї-Й-й-К-к-Л-л-М-м-Н-н-О-о-П-п-Р-р-С-с-Т-т-У-у-Ф-ф-Х-х-Ц-ц-Ч-ч-Ш-ш-Щ-щ-Ъ-ъ-Ы-ы-Ь-ь-Э-э-Ю-ю-Я-я".split("-");
        let en = "A-a-B-b-V-v-G-g-G-g-D-d-E-e-E-e-E-e-ZH-zh-Z-z-I-i-I-i-I-i-J-j-K-k-L-l-M-m-N-n-O-o-P-p-R-r-S-s-T-t-U-u-F-f-H-h-TS-ts-CH-ch-SH-sh-SCH-sch-'-'-Y-y-'-'-E-e-YU-yu-YA-ya".split("-");
        let res = "";
        for (let i = 0, l = str.length; i < l; i++) {
            let s = str.charAt(i), n = ru.indexOf(s);
            if (n >= 0) {
                res += en[n];
            }
            else {
                res += s;
            }
        }
        return res;
    }
    GenerateSeoUrl() {
        let url = this.Translit(this.newData.pageTitle.replace(/[\s]+/gi, "-"));
        this.newData.url = url.replace(/[^0-9a-z_-]+/gi, "").toLowerCase();
    }
    ValidateSeoUrl() {
        this.newData.url = this.Translit(this.newData.url.replace(/[. ,$@!^()'*]+/g, "-").toLowerCase());
    }
    async OnClickSubmit() {
        this.HasLoading = true;
        if (ApiEnter.CurrentSessionUUID != undefined) {
            const response = await ApiStaticPages.CreateStaticPage(ApiEnter.CurrentSessionUUID, this.newData.publish ? 1 : 0, this.newData.pageTitle, this.newData.content, this.newData.seoTitle, this.newData.description, this.newData.keywords, this.newData.url, this.newData.dontIndexPage ? 1 : 0, this.newData.dontShowSitemap ? 1 : 0);
            if (response) {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_created"),
                    icon: "success",
                }).then(() => {
                    this.$router.push(`/cms/static-pages/list`);
                });
            }
            else {
                sweetalert({
                    title: "Error",
                    text: "Error with create static page",
                    icon: "error",
                });
            }
        }
        this.HasLoading = false;
    }
};
StaticPageCreate = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, CKEditor },
    })
], StaticPageCreate);
export default StaticPageCreate;

import { __decorate } from "tslib";
import { Component, Prop, Vue } from "vue-property-decorator";
let LeftMenuTab = class LeftMenuTab extends Vue {
};
__decorate([
    Prop()
], LeftMenuTab.prototype, "icon", void 0);
__decorate([
    Prop()
], LeftMenuTab.prototype, "label", void 0);
__decorate([
    Prop()
], LeftMenuTab.prototype, "color", void 0);
LeftMenuTab = __decorate([
    Component({
        components: {},
    })
], LeftMenuTab);
export default LeftMenuTab;

import axios from "axios";
export default class ApiStaticPages {
    static async GetStaticPagesList(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-static-pages", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async GetStaticPageByUUID(session_uuid, uuid) {
        try {
            const result = await axios.get("/api/admin/get-static-page?uuid=" + uuid, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateStaticPagePublish(session_uuid, publish, uuid) {
        try {
            const result = await axios.post("/api/admin/update-static-page-publish", {
                publish: publish,
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async DeleteStaticPage(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-static-page", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async CreateStaticPage(session_uuid, publish, pageTitle, content, title, description, keywords, url, dontIndexPage, dontShowSitemap) {
        try {
            const result = await axios.post("/api/admin/create-static-page", {
                publish: publish,
                page_title: pageTitle,
                content: content,
                title: title,
                description: description,
                keywords: keywords,
                url: url,
                dont_index_page: dontIndexPage,
                dont_show_sitemap: dontShowSitemap,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateStaticPage(session_uuid, publish, pageTitle, content, title, description, keywords, url, dontIndexPage, dontShowSitemap, uuid) {
        try {
            const result = await axios.post("/api/admin/update-static-page", {
                publish: publish,
                page_title: pageTitle,
                content: content,
                title: title,
                description: description,
                keywords: keywords,
                url: url,
                dont_index_page: dontIndexPage,
                dont_show_sitemap: dontShowSitemap,
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return false;
        }
    }
}

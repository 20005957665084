import { __decorate } from "tslib";
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import ApiOrders from "@/api/ApiOrders";
import ApiEnter from "@/api/ApiEnter";
import sweetalert from "sweetalert";
let DeliveryAdress = class DeliveryAdress extends Vue {
    constructor() {
        super(...arguments);
        this.ModelData = {
            street_name: "",
            street_number: "",
            city: "",
            province: "",
            zip_code: "",
            country: "",
        };
    }
    closeModal() {
        return true;
    }
    setData() {
        this.ModelData.street_name = this.data.street_name;
        this.ModelData.street_number = this.data.street_number;
        this.ModelData.city = this.data.city;
        this.ModelData.province = this.data.province;
        this.ModelData.zip_code = this.data.zip_code;
        this.ModelData.country = this.data.country;
    }
    save() {
        return true;
    }
    async SaveData() {
        const response = await ApiOrders.UpdateDeliveryAddress(ApiEnter.CurrentSessionUUID, this.$route.params.order_uuid, this.ModelData.street_name, this.ModelData.street_number, this.ModelData.city, this.ModelData.province, this.ModelData.zip_code, this.ModelData.country);
        if (response) {
            this.save();
        }
        else {
            sweetalert({
                title: "Error",
                text: "Error to change data",
                icon: "error",
            });
            this.closeModal();
        }
    }
};
__decorate([
    Prop()
], DeliveryAdress.prototype, "isOpen", void 0);
__decorate([
    Prop()
], DeliveryAdress.prototype, "data", void 0);
__decorate([
    Emit("closeModal")
], DeliveryAdress.prototype, "closeModal", null);
__decorate([
    Watch("isOpen")
], DeliveryAdress.prototype, "setData", null);
__decorate([
    Emit("save")
], DeliveryAdress.prototype, "save", null);
DeliveryAdress = __decorate([
    Component({})
], DeliveryAdress);
export default DeliveryAdress;

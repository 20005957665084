import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import EUserEnum from "@/struct/user/EUserEnum";
import { Getter } from "vuex-class";
import ApiDelivery from "@/api/ApiDelivery";
import DataDelivery from "@/data/CMS/DataDelivery";
import sweetalert from "sweetalert";
let DeliveryParse = class DeliveryParse extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: this.$t("menu.home"),
            },
            {
                to: "/admin/blog",
                text: this.$t("menu.cms"),
            },
            {
                to: "/",
                text: "Tracking tool",
                disabled: true,
            },
        ];
        this.TableHeaders = DataDelivery.DeliveryParseHeaders;
        this.TableItems = [];
        this.DeliveryFile = null;
        this.SuccessCount = 0;
        this.IsLoading = false;
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    async UploadFile(loadToDb) {
        try {
            this.TableItems = [];
            this.SuccessCount = 0;
            if (!this.DeliveryFile)
                return;
            this.IsLoading = true;
            const response = await ApiDelivery.UploadCSVFile(ApiEnter.CurrentSessionUUID, this.DeliveryFile, loadToDb);
            if (typeof response != "boolean") {
                this.TableItems = response.failedOrders;
                this.SuccessCount = response.successCount;
                if (loadToDb) {
                    sweetalert({
                        title: "Success",
                        text: "Success change orders",
                        icon: "success",
                    });
                }
            }
        }
        catch (e) {
            console.error(e);
            sweetalert({
                title: "Error",
                text: "Error with CSV file",
                icon: "error",
            });
        }
        finally {
            this.IsLoading = false;
        }
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], DeliveryParse.prototype, "GetCurrentSessionUser", void 0);
DeliveryParse = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], DeliveryParse);
export default DeliveryParse;

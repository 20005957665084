import { __decorate } from "tslib";
import { Component, Ref, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import ApiSourceFiles from "@/api/ApiSourceFiles";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import dayjs from "dayjs";
import DataSourceFiles from "@/data/CMS/DataSourceFiles";
import ApiAdmin from "@/api/ApiAdmin";
import UtilFormValidation from "@/util/Form/UtilFormValidation";
import { Getter } from "vuex-class";
import EUserEnum from "@/struct/user/EUserEnum";
import UtilGeneral from "@/util/Common/UtilGeneral";
let SourceFiles = class SourceFiles extends Vue {
    constructor() {
        super(...arguments);
        this.ValidateAnchorField = UtilFormValidation.OnValidationSourceFileAnchorField;
        this.Breadcrumbs = DataSourceFiles.Breadcrumbs;
        this.TableHeaders = DataSourceFiles.TableHeaders;
        this.TableItems = [];
        this.isOpenDialog = false;
        this.isOpenEditDialog = false;
        this.newFile = {
            file: null,
            description: "",
            publish: true,
            anchor: "",
        };
        this.currentFile = {
            file_name: "",
            description: "",
            publish: true,
            update: "",
            created: "",
            uuid: "",
            anchor: "",
        };
    }
    OpenDialog() {
        this.isOpenDialog = !this.isOpenDialog;
        this.newFile.file = null;
        this.newFile.description = "";
        this.newFile.publish = true;
    }
    OpenEditDialog() {
        this.isOpenEditDialog = !this.isOpenEditDialog;
    }
    async CreateSourceFile() {
        const validate = this.NewFormValidate.validate();
        if (!validate) {
            return void 0;
        }
        if (ApiEnter.CurrentSessionUUID != undefined) {
            this.$forceUpdate();
            const file_uuid = await ApiSourceFiles.CreateFile(ApiEnter.CurrentSessionUUID, this.newFile.file != null ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newFile.file)) : "", this.newFile.description, this.newFile.publish ? 1 : 0, this.newFile.anchor);
            if (file_uuid == undefined || file_uuid.length != 36) {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
                return;
            }
            this.$forceUpdate();
            sweetalert({
                title: this.$t("general.success"),
                text: this.$t("general.success_created"),
                icon: "success",
            }).then(() => this.OpenDialog());
            await this.GetFiles();
        }
    }
    async ChangeFilePublish(item) {
        try {
            const response = await ApiSourceFiles.UpdateFilePublish(item.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.GetFiles();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    async ChangeFileInfo() {
        const file_name = this.newFile.file ? (await ApiAdmin.UploadFile(ApiEnter.CurrentSessionUUID, this.newFile.file, this.currentFile.file_name)) : "";
        try {
            const response = await ApiSourceFiles.UpdateFileInfo(file_name, this.currentFile.description, this.currentFile.publish ? 1 : 0, ApiEnter.CurrentSessionUUID, this.currentFile.uuid, this.currentFile.anchor);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: this.$t("general.success_updated"),
                    icon: "success",
                }).then(() => {
                    this.OpenEditDialog();
                    this.GetFiles();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error"),
                    icon: "info",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
        }
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    async openEditDialog(uuid) {
        this.currentFile.uuid = uuid;
        const fileInfo = await ApiSourceFiles.GetFileByUUID(ApiEnter.CurrentSessionUUID, uuid);
        if (fileInfo == undefined) {
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "info",
            });
            return;
        }
        this.OpenEditDialog();
        this.currentFile.file_name = fileInfo.file_name;
        this.currentFile.publish = fileInfo.publish;
        this.currentFile.description = fileInfo.description;
        this.currentFile.anchor = fileInfo.anchor;
        this.currentFile.update = dayjs.tz(fileInfo.update, "Europe/Rome").format("DD.MM.YYYY HH:mm:s");
        this.currentFile.created = dayjs.tz(fileInfo.create_at, "Europe/Rome").format("DD.MM.YYYY HH:mm:s");
        this.currentFile.uuid = fileInfo.uuid;
        ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, fileInfo.file_name).then(response => {
            this.newFile.file = new File([new Blob([response.data])], fileInfo.file_name.split("/")[8]);
        });
    }
    DeleteFile(uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("files.accept_delete"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiSourceFiles.DeleteFile(ApiEnter.CurrentSessionUUID, uuid);
                if (typeof response == "boolean") {
                    await sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    await this.GetFiles();
                }
                else {
                    await sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error"),
                        icon: "info",
                    });
                }
            }
        });
    }
    CopyLink(link) {
        UtilGeneral.CopyLink(link);
    }
    async GetFiles() {
        try {
            this.TableItems = await ApiSourceFiles.GetFiles(ApiEnter.CurrentSessionUUID);
        }
        catch (e) {
            console.error(e);
        }
    }
    ValidateAnchor() {
        this.newFile.anchor = this.newFile.anchor.replace(/[^0-9A-Za-z-_]/g, "");
    }
    ValidateEditAnchor() {
        this.currentFile.anchor = this.currentFile.anchor.replace(/[^0-9A-Za-z-_]/g, "");
    }
    async DownloadFile() {
        await ApiAdmin.GetFiles(ApiEnter.CurrentSessionUUID, this.currentFile.file_name).then(response => {
            let a = document.createElement("a");
            let file = new Blob([response]);
            a.href = URL.createObjectURL(file);
            a.download = this.currentFile.file_name.split("/")[8];
            a.click();
        });
    }
    formatDate(item) {
        return dayjs.tz(item, "Europe/Rome").format("DD-MM-YYYY (HH:mm:s)");
    }
    created() {
        this.GetFiles();
    }
};
__decorate([
    Ref("new-form")
], SourceFiles.prototype, "NewFormValidate", void 0);
__decorate([
    Getter("getCurrentSessionUser")
], SourceFiles.prototype, "GetCurrentSessionUser", void 0);
SourceFiles = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], SourceFiles);
export default SourceFiles;

export var EAdminPanelOffersPriceFor;
(function (EAdminPanelOffersPriceFor) {
    EAdminPanelOffersPriceFor[EAdminPanelOffersPriceFor["AlMese"] = 0] = "AlMese";
    EAdminPanelOffersPriceFor[EAdminPanelOffersPriceFor["OneMese"] = 1] = "OneMese";
    EAdminPanelOffersPriceFor[EAdminPanelOffersPriceFor["ConTwoMesiInclusi"] = 2] = "ConTwoMesiInclusi";
    EAdminPanelOffersPriceFor[EAdminPanelOffersPriceFor["ConThreeMesiInclusi"] = 3] = "ConThreeMesiInclusi";
    EAdminPanelOffersPriceFor[EAdminPanelOffersPriceFor["Other"] = 4] = "Other";
})(EAdminPanelOffersPriceFor || (EAdminPanelOffersPriceFor = {}));
export var EOffersColor;
(function (EOffersColor) {
    EOffersColor[EOffersColor["LightGray"] = 0] = "LightGray";
    EOffersColor[EOffersColor["LightRed"] = 1] = "LightRed";
    EOffersColor[EOffersColor["LightBlue"] = 2] = "LightBlue";
})(EOffersColor || (EOffersColor = {}));

import { __decorate } from "tslib";
import { Component, Vue } from "vue-property-decorator";
import sweetalert from "sweetalert";
import ApiEnter from "@/api/ApiEnter";
import StandartTemplate from "@/components/Template/StandartTemplate.vue";
import LeftMenuTab from "@/components/LeftMenu/LeftMenuTab.vue";
import PageHeader from "@/components/UI/PageHeader.vue";
import dayjs from "dayjs";
import EUserEnum from "@/struct/user/EUserEnum";
import { Getter } from "vuex-class";
import StaticPagesList from "@/data/StaticPages/DataStaticPages";
import ApiStaticPages from "@/api/ApiStaticPages";
let StaticPages = class StaticPages extends Vue {
    constructor() {
        super(...arguments);
        this.Breadcrumbs = [
            {
                to: "/",
                text: this.$t("menu.home"),
            },
            {
                to: "/admin/blog",
                text: this.$t("menu.cms"),
                disabled: true,
            },
            {
                to: "/static-pages",
                text: "Static Pages",
                disabled: true,
            },
        ];
        this.TableHeaders = StaticPagesList.TableHeaders;
        this.TableItems = [];
    }
    CanAdminAccess() {
        return this.GetCurrentSessionUser != undefined && this.GetCurrentSessionUser.system_role == EUserEnum.SystemRole.SuperAdmin;
    }
    async GetStaticPages() {
        try {
            const response = await ApiStaticPages.GetStaticPagesList(ApiEnter.CurrentSessionUUID);
            if (typeof response != "boolean") {
                this.TableItems = response;
            }
        }
        catch (e) {
            console.error(e);
        }
    }
    async ChangeStaticPagePublish(item) {
        try {
            const response = await ApiStaticPages.UpdateStaticPagePublish(ApiEnter.CurrentSessionUUID, item.publish ? 1 : 0, item.uuid);
            if (typeof response == "boolean") {
                sweetalert({
                    title: this.$t("general.success"),
                    text: "Static page publish updated",
                    icon: "success",
                }).then(() => {
                    this.GetStaticPages();
                });
            }
            else {
                await sweetalert({
                    title: this.$t("general.error"),
                    text: this.$t("general.request_error") + response,
                    icon: "error",
                });
            }
        }
        catch (e) {
            console.error(e);
            await sweetalert({
                title: this.$t("general.error"),
                text: this.$t("general.request_error"),
                icon: "error",
            });
        }
    }
    DeleteStaticPage(uuid) {
        sweetalert({
            title: this.$t("general.confirm"),
            text: this.$t("blog.delete_post"),
            icon: "warning",
            buttons: [this.$t("general.no"), this.$t("general.yes")],
        }).then(async (isConfirm) => {
            if (isConfirm == true) {
                const response = await ApiStaticPages.DeleteStaticPage(ApiEnter.CurrentSessionUUID, uuid);
                if (typeof response == "boolean") {
                    sweetalert({
                        title: this.$t("general.success"),
                        text: this.$t("general.success_deleted"),
                        icon: "success",
                    });
                    this.GetStaticPages();
                }
                else {
                    sweetalert({
                        title: this.$t("general.error"),
                        text: this.$t("general.request_error") + response,
                        icon: "error",
                    });
                }
            }
        });
    }
    created() {
        this.GetStaticPages();
    }
    formatDate(item) {
        console.log("item", item);
        if (item) {
            return dayjs.tz(item, "Europe/Rome").format("DD.MM.YYYY HH:mm:ss");
        }
        return "";
    }
};
__decorate([
    Getter("getCurrentSessionUser")
], StaticPages.prototype, "GetCurrentSessionUser", void 0);
StaticPages = __decorate([
    Component({
        components: { PageHeader, StandartTemplate, LeftMenuTab },
    })
], StaticPages);
export default StaticPages;

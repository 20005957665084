import i18n from "@/i18n/index.ts";
export default class StaticPagesList {
}
StaticPagesList.TableHeaders = [
    {
        text: i18n.t("table_headers.name"),
        value: "page_title",
    },
    {
        text: "Created at",
        value: "create_at",
    },
    {
        text: "Edited at",
        value: "updated_at",
    },
    {
        text: i18n.t("table_headers.action"),
        value: "action",
    },
];

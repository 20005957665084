import axios from "axios";
class ApiOffer {
    static async GetOffers(session_uuid) {
        try {
            const result = await axios.get("/api/shop/offer/get-offers", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get offers");
        }
    }
    static async GetOfferByUUID(session_uuid, offer_uuid) {
        try {
            const result = await axios.post("/api/shop/offer/get-offer-by", {
                offer_uuid: offer_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateOffer(session_uuid, name, priority, publish, category, price, data, sms, min, additional_data, int_min, countries, rates_abroad, free, activation_info, display_roaming, overview, note, design, photo_list, alt_list, photo_slide, alt_slide, photo_slide_lg, alt_slide_lg, photo_slide_m, alt_slide_m, display_offers, display_home, display_slider, active, tariff_overview, prospects_info, contract, title, description, keywords, url, external_data, external_id, other_text, price_for, additional_data_style, additional_data_two, additional_data_two_style, additional_data_three, additional_data_three_style, free_style, rates_abroad_style, int_min_style) {
        try {
            const result = await axios.post("/api/shop/offer/offer-create", {
                name: name,
                priority: priority,
                publish: publish,
                category: category,
                price: price,
                data: data,
                sms: sms,
                min: min,
                additional_data: additional_data,
                int_min: int_min,
                countries: countries,
                rates_abroad: rates_abroad,
                free: free,
                activation_info: activation_info,
                display_roaming: display_roaming,
                overview: overview,
                note: note,
                design: design,
                photo_list: photo_list,
                alt_list: alt_list,
                photo_slide: photo_slide,
                alt_slide: alt_slide,
                photo_slide_lg: photo_slide_lg,
                alt_slide_lg: alt_slide_lg,
                photo_slide_m: photo_slide_m,
                alt_slide_m: alt_slide_m,
                display_offers: display_offers,
                display_home: display_home,
                display_slider: display_slider,
                active: active,
                tariff_overview: tariff_overview,
                prospects_info: prospects_info,
                contract: contract,
                title: title,
                description: description,
                keywords: keywords,
                url: url,
                price_for: price_for,
                other_text: other_text,
                external_data: external_data,
                external_id: external_id == null ? -1 : external_id,
                additional_data_style: additional_data_style,
                additional_data_two: additional_data_two,
                additional_data_two_style: additional_data_two_style,
                additional_data_three: additional_data_three,
                additional_data_three_style: additional_data_three_style,
                free_style: free_style,
                rates_abroad_style: rates_abroad_style,
                int_min_style: int_min_style,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateOfferInfo(name, priority, publish, category, price, data, sms, min, additional_data, int_min, countries, rates_abroad, free, activation_info, display_roaming, overview, note, design, photo_list, alt_list, photo_slide, alt_slide, photo_slide_lg, alt_slide_lg, photo_slide_m, alt_slide_m, display_offers, display_home, display_slider, active, tariff_overview, prospects_info, contract, title, description, keywords, url, session_uuid, uuid, external_data, external_id, price_for, other_text, additional_data_style, additional_data_two, additional_data_two_style, additional_data_three, additional_data_three_style, free_style, rates_abroad_style, int_min_style) {
        try {
            const result = await axios.post("/api/shop/offer/offer-change", {
                name: name,
                priority: priority,
                publish: publish,
                category: category,
                price: Number(price),
                data: data,
                sms: sms,
                min: min,
                additional_data: additional_data,
                int_min: int_min,
                countries: countries,
                rates_abroad: rates_abroad,
                free: free,
                activation_info: activation_info,
                display_roaming: display_roaming,
                overview: overview,
                note: note,
                design: design,
                photo_list: photo_list,
                alt_list: alt_list,
                photo_slide: photo_slide,
                alt_slide: alt_slide,
                photo_slide_lg: photo_slide_lg,
                alt_slide_lg: alt_slide_lg,
                photo_slide_m: photo_slide_m,
                alt_slide_m: alt_slide_m,
                display_offers: display_offers,
                display_home: display_home,
                display_slider: display_slider,
                active: active,
                tariff_overview: tariff_overview,
                prospects_info: prospects_info,
                contract: contract,
                title: title,
                description: description,
                keywords: keywords,
                url: url,
                uuid: uuid,
                price_for: price_for,
                other_text: other_text,
                external_data: external_data,
                external_id: external_id == null ? -1 : external_id,
                additional_data_style: additional_data_style,
                additional_data_two: additional_data_two,
                additional_data_two_style: additional_data_two_style,
                additional_data_three: additional_data_three,
                additional_data_three_style: additional_data_three_style,
                free_style: free_style,
                rates_abroad_style: rates_abroad_style,
                int_min_style: int_min_style,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error get offer");
        }
    }
    static async UpdateOfferPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/shop/offer/change-offer-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteOffer(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/shop/offer/delete-offer", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetOfferCategories(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-offer-categories", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            throw new Error("Error get blog");
        }
    }
    static async GetOfferCategoryByUUID(session_uuid, offer_category_uuid) {
        try {
            const result = await axios.post("/api/admin/get-offer-category-by", {
                offer_category_uuid: offer_category_uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.data;
        }
        catch (e) {
            return undefined;
        }
    }
    static async CreateOfferCategory(session_uuid, name, priority, publish) {
        try {
            const result = await axios.post("/api/admin/offer-create-category", {
                name: name,
                publish: publish,
                priority: priority,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            console.error(e);
            if ("response" in e && "data" in e.response && "response" in e.response.data) {
                return e.response.data.response;
            }
        }
        return undefined;
    }
    static async UpdateOfferCategoryPublish(publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-offer-category-publish", {
                uuid: uuid,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async UpdateOfferCategoryInfo(name, priority, publish, session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/change-offer-category-info", {
                uuid: uuid,
                name: name,
                priority: priority,
                publish: publish,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            throw new Error("Error");
        }
    }
    static async DeleteOfferCategory(session_uuid, uuid) {
        try {
            const result = await axios.post("/api/admin/delete-offer-category", {
                uuid: uuid,
            }, {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return undefined;
        }
    }
    static async GetActiveOffers(session_uuid) {
        try {
            const result = await axios.get("/api/admin/get-active-offers", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
    static async UpdateBSSOffers(session_uuid) {
        try {
            const result = await axios.get("/api/web-api/admin/update-bss-offers", {
                headers: {
                    "x-tenant": "null",
                    "x-session-token": session_uuid,
                },
            });
            return result.data.response;
        }
        catch (e) {
            return false;
        }
    }
}
export default ApiOffer;

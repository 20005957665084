var ECompanyEnum;
(function (ECompanyEnum) {
    let CompanyStatus;
    (function (CompanyStatus) {
        CompanyStatus[CompanyStatus["NoActive"] = 0] = "NoActive";
        CompanyStatus[CompanyStatus["Active"] = 1] = "Active";
        CompanyStatus[CompanyStatus["Banned"] = 2] = "Banned";
        CompanyStatus[CompanyStatus["Deleted"] = 3] = "Deleted";
        CompanyStatus[CompanyStatus["__LENGHT"] = 4] = "__LENGHT";
    })(CompanyStatus = ECompanyEnum.CompanyStatus || (ECompanyEnum.CompanyStatus = {}));
})(ECompanyEnum || (ECompanyEnum = {}));
export default ECompanyEnum;
